<app-header2></app-header2>

<div class="content-regisform container my-3">
  <div class="row mt-1">
    <div class="col-12" style="display: flex;justify-content: space-around;">
      <div class="card rounded shadow-sm m-1" style="max-width: 732px;">
        <div class="px-3 pt-4">
          <div class="border-bottom" style="border-color: #386991!important;">
            <label class="font-weight-bold h5 text-center" style="color: #386991;">ลงทะเบียนอบรม AIMC</label>
            <br>
            <div class="float-right badge badge-danger" *ngIf="course.total>=course.max_user">เต็มแล้ว</div>
            <label class="font-weight-bold" style="color: #386991;">
              หลักสูตร : {{course.cg_title}}
              <span class="font-weight-light"></span>
            </label>
            <label class="font-weight-bold" style="color: #386991;">
              รอบ : {{course.title}}
              <span class="font-weight-light"></span>
            </label>
            <label class="font-weight-bold" style="color: #386991;">
              จำนวนผู้ลงทะเบียนเอง (
              <span> {{course.total}} </span>
              / {{course.max_user}} ) คน
            </label>

          </div>
          <div class="my-3">
            <form [formGroup]="formGroup">
              <div class="row">
                <div class="col-md-6 py-2">
                  <label class="font-weight-bold" style="color: #386991;">รหัสพนักงาน / รหัสตัวแทน <span
                      style="color: red;">*</span>
                    <!-- <i class="far fa-address-card"></i> -->
                  </label>
                  <input class="form-control" formControlName="user_ref" type="number" required>
                </div>
                <div class="col-md-6 py-2">
                  <label class="font-weight-bold" style="color: #386991;">รหัสบัตรประชาชน <span
                      style="color: red;">*</span>
                    <!-- {{json(formGroup.get("cid"))}} -->
                    <!-- <i class="far fa-id-card"></i> -->
                  </label>
                  <input class="form-control" formControlName="cid" type="text" required>
                  <div>
                    <span class="text-danger"
                      *ngIf="(formGroup.get('cid').touched ) &&( formGroup.get('cid').errors?.required||formGroup.get('cid').value.length!=13)">
                      กรุณากรอกตัวเลข 13 หลัก
                    </span>
                    <!-- {{formGroup.get("cid") |json}} -->
                  </div>
                </div>
                <div class="col-md-4 py-2">
                  <label class="font-weight-bold" style="color: #386991;">คำนำหน้า <span style="color: red;">*</span>
                    <!-- <i class="far fa-user-circle"></i> -->
                  </label>
                  <select name="" id="" class="form-control" formControlName="name_title" required>
                    <option value="นาย">นาย</option>
                    <option value="นางสาว">นางสาว</option>
                    <option value="นาง">นาง</option>
                  </select>
                </div>
                <div class="col-md-4 py-2">
                  <label class="font-weight-bold" style="color: #386991;">ชื่อ <span style="color: red;">*</span>
                    <!-- <i class="fas fa-pen"></i> -->
                  </label>
                  <input class="form-control" formControlName="first_name" type="text" required>
                </div>
                <div class="col-md-4 py-2">
                  <label class="font-weight-bold" style="color: #386991;">นามสกุล <span style="color: red;">*</span>
                    <!-- <i class="fas fa-pen"></i> -->
                  </label>
                  <input class="form-control" formControlName="last_name" type="text" required>
                </div>
                <div class="col-md-6 py-2">
                  <label class="font-weight-bold" style="color: #386991;">เบอร์โทร <span style="color: red;">*</span>
                    <!-- <i class="fas fa-phone"></i> -->
                  </label>
                  <input class="form-control" required="true" formControlName="number_phone" type="tel" required>
                  <span class="text-danger"
                    *ngIf="(formGroup.get('number_phone').touched ) &&( formGroup.get('number_phone').invalid)">
                    กรุณากรอกเบอร์โทรให้ถูกต้อง
                  </span>
                </div>
                <div class="col-md-6 py-2">
                  <label class="font-weight-bold" style="color: #386991;">Email <span style="color: red;">*</span>
                    <!-- <i class="fas fa-mail-bulk"></i> -->
                  </label>
                  <input class="form-control" required="true" formControlName="user_email" type="email" name="email"
                    required>
                  <span class="text-danger"
                    *ngIf="(formGroup.get('user_email').touched ) &&( formGroup.get('user_email').errors?.required||formGroup.get('user_email').errors?.email)">
                    กรุณากรอก Email ให้ถูกต้อง
                  </span>
                </div>
              </div>
              <div class="py-2">
                <button class="btn btn-info btn-block" (click)="save()"
                  [disabled]="formGroup.invalid">ลงทะเบียน</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer2></app-footer2>