<app-header2></app-header2>

<div id="body" class="content">
    <div class="header-page">
        <div class="p-4 pl-5">
            <h1 class="container p-30">
                
            </h1>
        </div>
    </div>
    <div class="container pb-5 pt-5">
      

        <div style="font-family: 'Kanit', sans-serif;">
            <div class="text-center">
                <h2 style="color: #205081">นโยบายการใช้คุกกี้ (Cookies Policy)</h2>
            </div>
    
            <div style="font-size: 18px;">
                <br>
                <p style="text-indent: 2.5em;">
                    สมาคมบริษัทจัดการลงทุน (“สมาคม”) อาจมีการใช้คุกกี้หรือเทคโนโลยีอื่นใดที่มีลักษณะใกล้เคียงกัน (“คุกกี้”) บนเว็บไซต์ของสมาคม เพื่อจำแนกท่านออกจากผู้ใช้งานรายอื่น ๆ ซึ่งจะช่วยให้สมาคมสามารถดำเนินงานในส่วนที่เกี่ยวข้องกับการใช้งานเว็บไซต์ให้ท่านได้ และช่วยให้สมาคมสามารถปรับปรุงเว็บไซต์ได้อีกด้วย
                </p>
                <br>
                <h3>คุกกี้คืออะไร</h3>
                <p style="text-indent: 2.5em;"> คุกกี้คือไฟล์ขนาดเล็กที่ประกอบด้วยตัวอักษรและตัวเลขที่สมาคม เก็บไว้บนเบราว์เซอร์ อุปกรณ์คอมพิวเตอร์ อุปกรณ์เชื่อมต่ออินเตอร์เน็ต เช่น สมาร์ทโฟน หรือแท็บเล็ตของท่านหรือบนฮาร์ดไดร์ฟในคอมพิวเตอร์ของท่าน เพื่อทำหน้าที่บันทึกข้อมูลและการตั้งค่าต่างๆ เช่น สถานะการเข้าใช้งานในปัจจุบัน ข้อมูลการตั้งค่าภาษา ข้อมูลเกี่ยวกับประวัติการเข้าใช้งานบนเว็บไซต์ เพื่อให้ท่านสามารถใช้บริการที่มีการใช้คุกกี้หรือเทคโนโลยีอื่นใดที่มีลักษณะใกล้เคียงกันได้อย่างต่อเนื่อง
                    คุกกี้มีข้อมูลที่ถูกเก็บไว้บนฮาร์ดไดร์ฟในคอมพิวเตอร์ของท่าน ท่านสามารถยอมรับหรือปฏิเสธการใช้งานคุกกี้ได้โดยการปรับการตั้งค่าในเบราว์เซอร์ของท่าน หากท่านต้องการทำเช่นนี้ โปรดดูที่เมนูช่วยเหลือของเบราว์เซอร์ของท่าน
                </p>
                <br>
                <h3>ประเภทของคุกกี้ที่สมาคมใช้ เช่น</h3>
                <p style="text-indent: 2.5em;">1. <span style="font-weight: 500;">คุกกี้ที่จำเป็นต้องใช้ (Strictly Necessary cookies)</span> คุกกี้เหล่านี้จำเป็นสำหรับการทำงานกับเว็บไซต์ และไม่สามารถปิดการใช้งานในระบบของเราได้ และโดยปกติแล้ว คุกกี้เหล่านี้จะถูกกำหนดไว้เพื่อตอบสนองต่อการดำเนินการร้องขอบริการของคุณ เช่น การตั้งค่าความเป็นส่วนตัว การเข้าสู่ระบบ หรือการกรอกแบบฟอร์ม คุณสามารถกำหนดให้เบราว์เซอร์ของคุณทำการบล็อก หรือเตือนให้คุณทราบเกี่ยวกับคุกกี้เหล่านี้ แต่นั่นอาจทำให้บางส่วนของไซต์ไม่สามารถทำงานได้</p>
                <p style="text-indent: 2.5em;">2. <span style="font-weight: 500;">คุกกี้เพื่อการวิเคราะห์/เพื่อประสิทธิภาพ (Analytic/Performance cookie)</span> คุกกี้เหล่านี้ช่วยเราในการนับจำนวนและที่มาของการเข้าชม เพื่อให้เราสามารถวัดและปรับปรุงประสิทธิภาพไซต์ของเราได้ นอกจากนี้ยังช่วยให้เราทราบว่าหน้าใดได้รับความนิยมมากที่สุดและน้อยที่สุด และทราบว่าผู้เยี่ยมชมเข้าดูส่วนใดบ้าง ข้อมูลทั้งหมดที่คุกกี้เหล่านี้จัดเก็บไว้จะถูกรวมไว้ด้วยกัน จึงทำให้ไม่สามารถระบุตัวตนได้ หากคุณไม่อนุญาตให้คุกกี้เหล่านี้ทำงาน เราจะไม่ทราบว่าคุณเข้าชมไซต์ของเราเมื่อใด</p>
                <p style="text-indent: 2.5em;">3. <span style="font-weight: 500;">คุกกี้เกี่ยวกับการทำงาน (Functional cookies)</span> คุกกี้เหล่านี้ช่วยในการทำงานของไซต์และการปรับแต่งไซต์ให้เหมาะกับผู้ใช้ เช่น วิดีโอและการสนทนาสด คุกกี้เหล่านั้นอาจได้รับการกำหนดโดยเราหรือผู้ให้บริการจากบริษัทอื่นที่เราได้เพิ่มบริการของพวกเขาลงในหน้าเพจ หากคุณไม่อนุญาตให้คุกกี้เหล่านี้ทำงาน ฟังก์ชันบางอย่างของไซต์อาจทำงานไม่ถูกต้อง</p>
                <p style="text-indent: 2.5em;">4. <span style="font-weight: 500;">คุกกี้ของบุคคลภายนอก (Third-Party Cookies)</span> คุกกี้ที่ติดตั้งโดยโดเมนอื่นที่นอกเหนือจากของเว็บไซต์ของสมาคมที่ท่านเข้าใช้งานอยู่ และสมาคมไม่สามารถควบคุมคุกกี้เหล่านี้ได้ ถ้าท่านได้เยี่ยมชมเว็บไซต์หนึ่งและมีการสร้างคุกกี้ผ่านเว็บไซต์นั้น นี่คือคุกกี้ของบุคคลที่สาม คุกกี้เหล่านี้อาจถูกสร้างขึ้นโดยบุคคลภายนอกที่เป็นเจ้าของการบริการที่สมาคมใส่เข้าไปในเว็บไซต์ของสมาคม ท่านอาจตั้งค่าบราวเซอร์เพื่อจำกัดการเก็บข้อมูลส่วนตัวของท่าน</p>
                <br>
                <h3>การตั้งค่าและการปฏิเสธคุกกี้</h3>
                <p style="text-indent: 2.5em;"> คุณสามารถเลือกปฏิเสธการทำงานของคุกกี้ได้ตามความต้องการของคุณ โดยการตั้งค่าเบราว์เซอร์หรือการตั้งค่าความเป็นส่วนตัวของคุณ เพื่อระงับการเก็บรวบรวมข้อมูลโดยคุกกี้ในอนาคต อย่างไรก็ตาม หากคุณตั้งค่าเบราว์เซอร์หรือค่าความเป็นส่วนตัวของคุณด้วยการปฏิเสธการทำงานของคุกกี้ทั้งหมด คุณอาจไม่สามารถใช้งานฟังก์ชั่นบางอย่างหรือทั้งหมดบนเว็บไซต์และ/หรือแอปพลิเคชันของธนาคารได้อย่างมีประสิทธิภาพ</p><br>
                <h3>การเปลี่ยนแปลงนโยบาย</h3>
                <p style="text-indent: 2.5em;"> นโยบายการใช้คุกกี้นี้อาจมีการปรับปรุงแก้ไขหรือเปลี่ยนแปลงตามความเหมาะสมเพื่อให้เป็นไปตามกฎหมายและกฎระเบียบที่เกี่ยวข้อง</p>
    
            </div>
    
    
    
        </div>
        
    </div>
</div>

<app-footer2></app-footer2>