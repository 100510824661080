<!DOCTYPE html>
<html lang="en">

    <head>
        <meta charset="utf-8" />
        <meta name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content />
        <meta name="author" content />
        <title>Stylish Portfolio - Start Bootstrap Template</title>
        <!-- Favicon-->
        <link rel="icon" type="image/x-icon" href="assets/favicon.ico" />
        <!-- Font Awesome icons (free version)-->
        <script src="https://use.fontawesome.com/releases/v5.15.3/js/all.js"
            crossorigin="anonymous"></script>
        <!-- Simple line icons-->
        <link
            href="https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/css/simple-line-icons.min.css"
            rel="stylesheet" />
        <!-- Google fonts-->
        <link
            href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,300italic,400italic,700italic"
            rel="stylesheet" type="text/css" />
        <!-- Core theme CSS (includes Bootstrap)-->
    </head>

    <body id="page-top">

        <header class="masthead d-flex align-items-center">
            <div class="container px-4 px-lg-5 text-center">
                <img src="../assets/images/logo1.png" class="mb-md"
                    style="    margin-bottom: 90px;            width: 100%;            max-width: 366px;">
                <h1 class="mb-1">ปิดปรับปรุงระบบชั่วคราว</h1>
                <p style="color: #fff;font-size: 20px;">
                    ศูนย์ความรู่วิชาชีพทางการเงิน AIMC ขอแจ้งปิดปรับปรุงระบบ
                    ตั้งแต่วันที่ 16 ตุลาคม 2566 เวลา 13:00 - วันที่ 17 ตุลาคม
                    2566 เวลา 16:00 น. <br>

                    และจะขยายเวลาการอบรมให้ทุกท่านจำนวน 3 วัน
                    ขออภัยในความไม่สะดวก
                </p>
                <!-- <p style="color: #fff;font-size: 17px;" >
                ท่านสามารถเข้าระบบได้อีกครั้งในเวลาประมาณ 00:50 น. เป็นต้นไป<br />
                 ขออภัยในความไม่สะดวก
            </p> -->
                <h3 class="mb-5"><em>E-learning System by AIMC</em></h3>
                <!-- <a class="btn btn-primary btn-xl" href="#about">Find Out More</a> -->
            </div>
        </header>

        <script
            src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/js/bootstrap.bundle.min.js"></script>
        <!-- Core theme JS-->
        <script type="text/javascript">
         
        /*!
    * Start Bootstrap - Stylish Portfolio v6.0.0 (https://startbootstrap.com/theme/stylish-portfolio)
    * Copyright 2013-2021 Start Bootstrap
    * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-stylish-portfolio/blob/master/LICENSE)
    */
        window.addEventListener('DOMContentLoaded', event => {

            const sidebarWrapper = document.getElementById('sidebar-wrapper');
            let scrollToTopVisible = false;
            // Closes the sidebar menu
            const menuToggle = document.body.querySelector('.menu-toggle');
            menuToggle.addEventListener('click', event => {
                event.preventDefault();
                sidebarWrapper.classList.toggle('active');
                _toggleMenuIcon();
                menuToggle.classList.toggle('active');
            })

            // Closes responsive menu when a scroll trigger link is clicked
            var scrollTriggerList = [].slice.call(document.querySelectorAll('#sidebar-wrapper .js-scroll-trigger'));
            scrollTriggerList.map(scrollTrigger => {
                scrollTrigger.addEventListener('click', () => {
                    sidebarWrapper.classList.remove('active');
                    menuToggle.classList.remove('active');
                    _toggleMenuIcon();
                })
            });

            function _toggleMenuIcon() {
                const menuToggleBars = document.body.querySelector('.menu-toggle > .fa-bars');
                const menuToggleTimes = document.body.querySelector('.menu-toggle > .fa-times');
                if (menuToggleBars) {
                    menuToggleBars.classList.remove('fa-bars');
                    menuToggleBars.classList.add('fa-times');
                }
                if (menuToggleTimes) {
                    menuToggleTimes.classList.remove('fa-times');
                    menuToggleTimes.classList.add('fa-bars');
                }
            }

            // Scroll to top button appear
            document.addEventListener('scroll', () => {
                const scrollToTop = document.body.querySelector('.scroll-to-top');
                if (document.documentElement.scrollTop > 100) {
                    if (!scrollToTopVisible) {
                        fadeIn(scrollToTop);
                        scrollToTopVisible = true;
                    }
                } else {
                    if (scrollToTopVisible) {
                        fadeOut(scrollToTop);
                        scrollToTopVisible = false;
                    }
                }
            })
        })

        function fadeOut(el) {
            el.style.opacity = 1;
            (function fade() {
                if ((el.style.opacity -= .1) < 0) {
                    el.style.display = "none";
                } else {
                    requestAnimationFrame(fade);
                }
            })();
        };

        function fadeIn(el, display) {
            el.style.opacity = 0;
            el.style.display = display || "block";
            (function fade() {
                var val = parseFloat(el.style.opacity);
                if (!((val += .1) > 1)) {
                    el.style.opacity = val;
                    requestAnimationFrame(fade);
                }
            })();
        };
      

    </script>
    </body>

</html>