<app-header2></app-header2>
<div id="body" class="content">
  <div class="header-page">
    <div class="p-4 pl-5">
      <h1 class="container p-30">
        ติดต่อเรา
      </h1>
    </div>
  </div>
  <div [innerHTML]="_contact.contact" class="container"></div>
</div>
<app-footer2></app-footer2>
