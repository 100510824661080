import { ActivatedRoute } from '@angular/router';
import { ConnectApiService } from './../connect.api.service';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-approve',
  templateUrl: './approve.component.html',
  styleUrls: ['./approve.component.scss']
})
export class ApproveComponent implements OnInit {
  @ViewChild('_ischeck') checkbox: ElementRef<HTMLInputElement>;
  a: number = 0
  b: number = 0
  page: any = '1'
  url: string = ''
  data = {
    user: [],
    data: {}
  };
  constructor(public api: ConnectApiService, private route: ActivatedRoute, public ref: ChangeDetectorRef) {
    this.url = this.route.snapshot.paramMap.get("code")
  }

  ngOnInit(): void {
    this.checkPass()
    setTimeout(() => {
      let auth_token = localStorage.getItem("approve_token");
      console.log(auth_token);
      if (this._passcodeState == 1 && auth_token && auth_token != null) {
        this.load(this.url, this.page)
        this.round(this.url)
      }
    }, 500);
  }

  checkPass() {
    setTimeout(() => {
      let check: any = JSON.parse(this.api.storage_get('_passcodeState')) ? JSON.parse(this.api.storage_get('_passcodeState')) : { code: null }
      if (check.code == this.url) {
        if (check.state == 1) {
          this._passcodeState = 1
        } else {
          this._passcodeState = 2
        }
      } else {
        this._passcodeState = 2
      }
    }, 300);
  }

  passcode = ''
  _passcodeState = 0
  _passcodeStateStr = ''
  confirmPass(pass) {
    // this.api.postData("checkpasscode")
    this.api.postData_nologin("../Service/checkPassCode_post", { url: this.url, pass: pass }).then((res: any) => {
      // console.log(res);
      if (res.flag == 1) {
        this.api.storage_set_string('approve_token', res.token)
        this.api.storage_set('_passcodeState', { code: this.url, state: 1 })
        this._passcodeState = 3
        this._passcodeStateStr = ''
        setTimeout(() => {
          location.reload()
        }, 300);
      } else {
        this._passcodeState = 2
        this._passcodeStateStr = 'รหัสผ่านไม่ถูกต้อง!'
      }
    })
    // this.api.getData_nologin('../Service/checkPassCode/' + this.url + '/' + pass).then((res: any) => {
    //   if (res.flag == 1) {
    //     this.api.storage_set('approve_token', res.token)
    //     this.api.storage_set('_passcodeState', { code: this.url, state: 1 })
    //     this._passcodeState = 3
    //     this._passcodeStateStr = ''
    //     setTimeout(() => {
    //       location.reload()
    //     }, 300);
    //   } else {
    //     this._passcodeState = 2
    //     this._passcodeStateStr = 'รหัสผ่านไม่ถูกต้อง!'
    //   }
    // })
  }

  max_user: any = 0
  user_approved: any = 0
  data_page: any = []
  load(code, page) {
    this.api.getData_approve('../Service/load_json/' + code + '/' + page).then((res: any) => {
      // if (res.logout) {
      //   window.location.href = 'https://e-learning.aimc.or.th/';
      // }
      this.data = res;
      this.max_user = res.data.max_user
      this.user_approved = res.data.user_approved
      let count_user = res.data.count_user
      this.data_page = []
      if (count_user > 0) {
        for (let i = 1; i <= count_user; i++) {
          this.data_page.push(i.toString())
        }
      } else {
        this.data_page = ['0']
      }

      this.data.user.forEach((el, index) => {
        el._ischeck = false
      });
    })
  }

  course: any = { cg_title: '' }
  round(code) {
    this.api.getData_approve("../Service/loadRound/" + code).then((res: any) => {
      // if (res.logout) {
      //   window.location.href = 'https://e-learning.aimc.or.th/';
      // }
      this.course = res
    })
  }

  check: any = []
  approve(type) {
    this.check = []
    Swal.fire({
      icon: 'question',
      title: 'ยืนยันการ ' + type + ' ' + this.user_select + ' คน?',
      text: 'หาก ' + type + ' แล้วจะไม่สามารถแก้ไขได้ด้วยตนเอง โปรดติดต่อ Admin หากต้องการแก้ไข',
      showCancelButton: true,
      confirmButtonText: `Confirm`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.data.user.forEach((el, index) => {
          if (el._ischeck && el.status == 0) {
            el.status = 2
            if (type == 'Approve') {
              el.status = 1
            }

            if (this.check.length < this.max_user) {
              this.check.push(el)
            }
          }
        })
        this.api.postData("../Service/approve_user_multi", this.check).then((res) => {
          this.load(this.url, 1)
          this.user_select = 0
          this.check_all = false
          if (type == 'Approve') {
            Swal.fire('Approved เรียบร้อยแล้ว', '', 'success');
          } else {
            Swal.fire('Rejected เรียบร้อยแล้ว', '', 'success');
          }

        })
      }
    })
  }

  changePage(page) {
    this.load(this.url, page)
    if (page == 0) {
      this.page = 1
    }
  }

  onCopy(val) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'คัดลอกแล้ว',
      showConfirmButton: false,
      timer: 1500
    })
  }

  export(code) {
    this.api.getData_approve("../Service/export_user_register/" + code).then((res: any) => {
      if (res) {
        location.href = res.link_excel
      }
    })
  }

  exit() {
    Swal.fire({
      icon: 'question',
      title: 'ต้องการออกจากระบบหรือไม่?',
      showCancelButton: true,
      confirmButtonText: 'ออกจากระบบ',
      confirmButtonColor: '#dc3545',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.getData_approve("../Service/approve_exit").then((res: any) => {
          location.href = this.api.main_url() + 'api/service/approve/' + this.url
          localStorage.removeItem('_passcodeState');
        })
      }
    })
  }

  check_all = false
  onCheckAll() {
    this.user_select = 0
    this.data.user.forEach((el) => {
      if (this.check_all) {
        el._ischeck = true
      } else {
        el._ischeck = false
      }

      if (this.check_all && el.status == 0) {
        this.user_select++
      }
    });
  }

  check_model = false
  user_select = 0
  onCheck(val, index) {
    if (!this.data.user[index]._ischeck) {
      this.data.user[index]._ischeck = true
      this.user_select++
    } else {
      this.data.user[index]._ischeck = false
      this.user_select--
    }
  }

  search: any = ''
  findUser(s) {
    let data_s = {
      s: s,
      code: this.url,
      page: 1
    }
    this.api.postData_nologin('../Service/findUser', data_s).then((res: any) => {
      if (res.flag == 1) {
        this.data = res;
        this.data.user.forEach((el, index) => {
          el._ischeck = false
        });
      } else {
        this.load(this.url, 1)
      }
      this.ref.detectChanges()
    })
  }
}
