<app-header2></app-header2>
<div id="body" class="content">
  <div class="header-page">
    <div class="p-4 pl-5">
      <h1 class="container p-30">
        คำถามที่พบบ่อย
      </h1>
    </div>
  </div>

  <div class="container my-5">
    <div class="row">
      <div class="col-12" *ngFor="let faq of _FAQ;let i = index">
        <p class="fs-3 q" (click)="onPick(i,faq.val)" data-toggle="collapse" [attr.data-target]="'#collapse'+(i+1)">
          <i class="mx-3 {{faq.class}}"></i>
          Q : {{faq.question}}
        </p>
        <div class="collapse pl-4" id="collapse{{i+1}}">
          <div class="pl-5 fs-3 pt-2">
            <p>
              A : {{faq.answer}}
            </p>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer2></app-footer2>
