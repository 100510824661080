<app-header></app-header>
<div class="bg learning-page" (document:mousemove)="mouseMove($event)">
    <div class="header2">
        <div class="container">
            <div class="row">
                <div class="header-back"><a class="btn btn-back btn-sm"
                        [routerLink]="'/topic/'+courseid+'/'+topicid+'/'+rt_id+''">
                        กลับ  </a>
                </div>
                <div class="header-topic">
                    <h3>{{data.detail.topic}}</h3>
                    <!-- <button (click)="show_logout()" >show log</button> -->
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <!-- <button (click)="create_cer()" style="margin-top: 20px;" > create</button> -->
            <div class="videodiv col">
                <div class="pull-right">

                    <!-- {{data.detail.is_sliding}} {{list_selected.status}} {{seconds}} -->
                    <div [hidden]="!(data.detail.is_sliding ==
                        '1'||list_selected.status==2||(seconds==0&&list_selected.type!='video'||user.type=='1'))">
                        <!-- อนุญาติ -->
                        <button class="btn btn-primary btn-rounded btn-sm"
                            style="margin-top: -4px;"
                            (click)="play_next_ls()"
                            *ngIf="is_show_next()"><span
                                class="text_text">บทเรียนถัดไป </span>
                            <img style="width: 9px;"
                                src="../../assets/images/Icon_ionic-ios-arrow-back2.png">
                        </button>
                    </div>
                  
                    <div style="margin-right: 26px;"
                    [hidden]="(list_selected.type=='test'||data.detail.is_sliding=='1'||user.type=='1'||list_selected.status==2||(seconds==0&&list_selected.type!='video'))">
                 <!-- จับเวลา -->
                 <span style="font-weight: bold;"
                       [hidden]="!(list_selected.type=='video')">
                   <img src="../../assets/images/Icon_feather-clock.png"
                        *ngIf="list_selected.type!='test'"
                        width="18">&nbsp;&nbsp;
                   {{getFormattedTime(seconds)}}
                   /
                   {{getFormattedTime(video_playing.video.duration)}}
                 </span>
                 <span [hidden]="(list_selected.type=='video'||user.type=='1')"
                       style="font-weight: bold;">
                   <img src="../../assets/images/Icon_feather-clock.png"
                        *ngIf="list_selected.type!='test'"
                        width="18">&nbsp;&nbsp;
                   {{getFormattedTime(seconds)}}
                 </span>
               </div>
                </div>

                <h2 class="title">
                    {{title}}
                </h2>

                <div class="survey" *ngIf="list_selected.type=='survey'">
                    <table class="table table-striped shadow-sm survey_max">
                        <thead style="background-color: #1E73BE;color: #fff;">
                            <tr>
                                <th>คำภาม</th>
                                <th>มากที่สุด(5)</th>
                                <th>มาก(4)</th>
                                <th>ปานกลาง(3)</th>
                                <th>น้อย(2)</th>
                                <th>น้อยที่สุด(1)</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of survey;let i= index">

                            <tr *ngIf="item.type=='0'">
                                <td>{{i+1}}. {{item.title}}</td>

                                <td class="text-center" *ngFor="let j of
                                    [5,4,3,2,1]">

                                    <input type="radio" id="id{{i}}{{j}}"
                                        [(ngModel)]="item.ans" value="{{j}}"
                                        [value]="j" name="ans{{i}}"
                                        [name]="'ans'+i">
                                </td>

                            </tr>
                            <tr *ngIf="item.type=='1'">
                                <td>{{i+1}}. {{item.title}}</td>

                                <td class="text-center" colspan="5">
                                    <textarea class="form-control"
                                        [(ngModel)]="item.ans">

                                    </textarea>

                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-striped shadow-sm survey_min">
                        <thead style="background-color: #1E73BE;color: #fff;">
                            <tr style="text-align: center;">
                                <th>(5)</th>
                                <th>(4)</th>
                                <th>(3)</th>
                                <th>(2)</th>
                                <th>(1)</th>
                            </tr>
                        </thead>

                        <tbody *ngFor="let item of survey;let i= index">
                            <tr>
                                <td colspan="5">{{i+1}}. {{item.title}}</td>
                            </tr>
                            <tr *ngIf="item.type=='0'">
                                <td class="text-center" *ngFor="let j of
                                    [5,4,3,2,1]">
                                    <input type="radio" id="id{{i}}{{j}}"
                                        [(ngModel)]="item.ans" value="{{j}}"
                                        [value]="j" name="ans{{i}}"
                                        [name]="'ans'+i">
                                </td>
                            </tr>
                            <tr *ngIf="item.type=='1'">
                                <td class="text-center" colspan="5">
                                    <textarea class="form-control"
                                        [(ngModel)]="item.ans">

                                    </textarea>

                                </td>
                            </tr>
                        </tbody>

                    </table>
                    <button class="btn btn-info btn-sm"
                        [disabled]="survey_disabled()"
                        (click)="send_survey()"><img
                            src="../../assets/images/Icon feather-send.png" alt>
                        ส่งข้อมูล</button>
                </div>
                <h4
                    *ngIf="list_selected.type=='test'">{{list_selected.exam.title}}</h4>
                <div class="test-viewer"
                    *ngIf="list_selected.type=='test'&&show_test">
                    <div class="test-finish" *ngIf="test_success">
                        <div class="title2">
                            ผลสอบของคุณ
                            <div
                                style="float: right; font-weight: normal;">{{get_text_datetime(test_result.date_time)}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="percen-text-not-pass"
                                    *ngIf="test_result.percen<test_result.pass_percen-0">
                                    {{test_result.percen}}%
                                </div>
                                <div
                                    *ngIf="test_result.percen>=test_result.pass_percen-0"
                                    class="percen-text-pass">
                                    {{test_result.percen}}%
                                </div>
                                <div class="line"></div>
                                <div class="percen-pass">
                                    100%

                                </div>
                                <div class="danger"
                                    *ngIf="test_result.percen<test_result.pass_percen-0">
                                    ไม่ผ่าน
                                </div>

                                <div class="pass"
                                    *ngIf="test_result.percen>=test_result.pass_percen-0">
                                    ผ่าน
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <dl>
                                    <dt> เวลาที่ใช้ทำแบบทดสอบ</dt>
                                    <dd>{{get_time_text2(test_result.time)}}</dd>
                                    <dt>คะแนนที่ได้</dt>
                                    <dd>{{test_result.count_correct}} /
                                        {{test_result.count_all}} คะแนน</dd>
                                    <dt>จำนวนข้อ</dt>
                                    <dd>{{test_result.count_all}} ข้อ</dd>
                                    <dt>ข้อที่ถูก</dt>
                                    <dd>{{test_result.count_correct}} ข้อ</dd>
                                    <dt>ข้อที่ผิด</dt>
                                    <dd>{{test_result.count_not_correct}}
                                        ข้อ</dd>
                                    <dt>ข้อที่ข้าม</dt>
                                    <dd>{{test_result.count_not_check}} ข้อ</dd>
                                    <dt>เปอร์เซนต์ที่ต้องผ่าน</dt>
                                    <dd>{{test_result.pass_percen}}% </dd>
                                </dl>
                                <span style="color: #bc0c06;"
                                    *ngIf="test_result.percen>=test_result.pass_percen-0&&data.detail.cer_id!='0'&&data.detail.cer_url=='-'">
                                    ท่านสามารถดาวโหลดใบประกาศในอีก 3 - 5
                                    วันทำการ
                                </span>
                                <button class="btn btn-warning btn-sm"
                                    (click)="download_cer(data.detail.cer_url)"
                                    *ngIf="test_result.percen>=test_result.pass_percen-0&&data.detail.cer_id!='0'&&data.detail.cer_url!=''&&data.detail.cer_url!='-'">
                                    <img src="../../assets/images/medal.png"
                                        style="width: 18px;" alt> ดาวน์โหลด
                                    Certificate
                                </button>
                                <button class="btn btn-info btn-sm ml-sm"
                                    style="margin-left: 15px;"
                                    (click)="load_survey()"
                                    *ngIf="test_result.percen>=test_result.pass_percen-0&&data.detail.sf_id
                                    != '0'">
                                    ทำแบบสอบถาม
                                </button>

                                <button class="btn btn-primary btn-rounded
                                    btn-sm" style="margin-left: 10px;"
                                    (click)="play_next_ls()"
                                    *ngIf="is_show_next()&&test_result.percen>=test_result.pass_percen-0">บทเรียนถัดไป
                                    <img style="width: 9px; margin-left: 5px;"
                                        src="../../assets/images/Icon_ionic-ios-arrow-back2.png"></button>
                                <button class="btn btn-warning btn-sm"
                                    (click)="repeat_test()"
                                    *ngIf="test_result.percen<test_result.pass_percen-0&&test_result.balance>0">
                                    ทำซ้ำ
                                    ({{list_selected.exam.ex_repeat-test_result.balance}}/{{list_selected.exam.ex_repeat}})
                                    <img src="../../assets/images/Icon
                                        material-replay.png" style="width:
                                        18px;" alt>
                                </button>
                            </div>

                        </div>
                    </div>
                    <div class="intro_test"
                        *ngIf="show_intro_test&&!test_success">
                        <div class="row">
                            <div class="col">จำนวน</div>
                            <div class="col">{{list_selected.exam.count}}
                                คำถาม</div>
                        </div>
                        <div class="row">
                            <div class="col">จำกัดเวลา</div>
                            <div class="col">{{list_selected.exam.time}}</div>
                        </div>
                        <div class="row">
                            <div class="col">การสอบใหม่</div>
                            <div class="col">สอบใหม่ได้
                                {{list_selected.exam.ex_repeat}} ครั้ง</div>
                        </div>
                        <div class="row">
                            <div class="col">สอบแล้ว</div>
                            <div class="col">
                                {{get_count_test(list_selected)}}/{{list_selected.exam.ex_repeat}}
                                ครั้ง
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">เกณฑ์การผ่าน</div>
                            <div class="col">ตั้งแต่
                                {{list_selected.exam.percent}}% ขึ้นไป</div>
                        </div>
                        <div class="row">
                            <div class="col text-warn-900 text-center mt-1"
                                *ngIf="get_count_test(list_selected)>=list_selected.exam.ex_repeat-0"
                                style="color: #dc2626;">
                                คุณทำข้อสอบครบจำนวนครั้งที่กำหนดแล้ว
                                หากต้องการทำอีกครั้งให้ติดต่อผู้ดูแลระบบ
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="get_count_test(list_selected)<list_selected.exam.ex_repeat-0">
                            <button class="btn btn-info btn-sm btn-start"
                                (click)="start_test()">
                                <img style="width: 15px; margin-right: 5px;"
                                    src="../../assets/images/Icon
                                    awesome-play.png" alt>
                                เริ่มทำแบบทดสอบ</button>
                        </div>
                    </div>

                    <div
                        [hidden]="test_show_finish||!show_test||show_intro_test||test_success">
                        <div class="text_header">
                            ข้อ {{(test_active-1)%test_data.test.length+1}} /
                            {{test_data.test.length}}
                            <div class="pull-right" style="float: right;
                                font-weight: bold;font-size: 22px;">
                                {{add0(floor(seconds/60/60))}}:{{add0(floor(seconds/60%60))}}:{{add0(floor(seconds%60))}}
                            </div>
                        </div>
                        <hr>
                        <ngb-carousel #carouselTest
                            [showNavigationArrows]="false"
                            [showNavigationIndicators]="false"
                            (slide)="testonSlide($event)" animation="true"
                            loop="false" [interval]="10000000">
                            <ng-template ngbSlide *ngFor="let test of
                                test_data.test;let i= index">
                                <div class="test_div">
                                    <div>{{i+1}} : <span
                                            [innerHtml]="test.title"></span></div>
                                    <div *ngIf="test.url!=''">
                                        <img
                                            [src]="api.main_url()+ 'api/Media/'+test.url"
                                            (click)="show_pic(api.main_url()+ 'api/Media/'+test.url)"
                                            class="img_exam" alt>
                                    </div>
                                    <ul class="mt-sm">
                                        <li *ngFor="let ans of test.options;let
                                            j= index">
                                            <div style="display:
                                                inline-flex;width: 100%;">
                                                <input type="radio"
                                                    style="margin-top: 6px;"
                                                    id="id{{i}}{{j}}"
                                                    [(ngModel)]="test.ans"
                                                    (ngModelChange)="change_test(0)"
                                                    value="{{j}}" [value]="j"
                                                    name="ans{{i}}"
                                                    [name]="'ans'+i">
                                                <label
                                                    style="margin-left: 10px;
                                                    cursor: pointer; width:
                                                    calc(100% - 25px);"
                                                    for="id{{i}}{{j}}"
                                                    [innerHtml]="ans.title">
                                                </label>
                                            </div>
                                            <div *ngIf="ans.url&&ans.url!=''">
                                                <img
                                                    [src]="api.main_url()+ 'api/Media/'+ans.url"
                                                    style="margin-bottom: 10px;
                                                    cursor: pointer;"
                                                    (click)="show_pic(api.main_url()+ 'api/Media/'+ans.url)"
                                                    class="img_exam" alt>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </ng-template>
                        </ngb-carousel>
                        <button class="btn btn-primary btn-rounded btn-sm"
                            style="margin-left: 20px;"
                            [disabled]="(test_active-1)%test_data.test.length+1==test_data.test.length"
                            (click)="next_test(carouselTest)">next <img
                                src="../../assets/images/Icon_ionic-ios-arrow-back2.png"></button>
                        <button class="btn btn-warning btn-rounded btn-sm"
                            style="float: right;"
                            [disabled]="floor(min_time_test)>0"
                            [hidden]="(!test_finish&&show_intro_test)"
                            (click)="test_finish_click(carouselTest)">
                            <span *ngIf="min_time_test>0">
                                ({{min_time_test}})</span>
                            ส่งข้อสอบ <img
                                src="../../assets/images/Icon_material-send.png"></button>

                        <!-- <button class="btn btn-success btn-rounded btn-sm" [hidden]="!(test_finish)"
                            [disabled]="!test_select_all()" style="float: right;"
                            (click)="test_finish_click(carouselTest)">
                            เสร็จสิ้น </button> -->
                        <button class="btn btn-primary btn-rounded btn-sm"
                            [disabled]="test_first" style="float:left;"
                            (click)="back_test(carouselTest)"><img
                                src="../../assets/images/Icon_ionic-ios-arrow-back-1.png">
                            Prev</button>
                    </div>
                    <div [hidden]="!test_show_finish">
                        <h5 style="margin:100px 0px;text-align: center;">
                            <div>
                                ทำถูก {{test_sum_score}} ข้อ
                            </div>
                            <div>
                                จากทั้งหมด {{test_data.test.length}} ข้อ
                            </div>
                        </h5>
                    </div>
                </div>
                <div style="width: 100%;height: 30px;"
                    *ngIf="list_selected.type=='test'&&show_intro_test"></div>
                <div class="info-viewer" *ngIf="list_selected.type=='info'">
                    <ngb-carousel (slide)="onSlide($event)" #carousel_info
                        [showNavigationArrows]="false"
                        [interval]="1000000" [showNavigationIndicators]="false">
                        <ng-template ngbSlide *ngFor="let item of
                            list_selected.info.info_data">
                            <div class="picsum-img-wrapper">
                                <img style="width: 100%;"
                                    [src]="api.main_url()+ 'api/Media/'+item.url"
                                    alt>
                            </div>
                        </ng-template>
                    </ngb-carousel>

                </div>
                <div class="canva-viewer" *ngIf="list_selected.type=='canva'">
                    <div class="container-sm">
                        <div style="position: relative; width: 100%; height: 0;
                            padding-top: 75.0000%;
                         box-shadow: 0 2px 8px 0
                            rgba(63,69,81,0.16); margin-top: 10px;
                            margin-bottom: 0.9em; overflow: hidden;
                            border-radius: 8px; will-change: transform;">
                            <iframe loading="lazy"
                                style="position: absolute; width: 100%; top: 0; left: 0; border: none; padding:
                                0;margin: 0;" [src]="urlCanva"
                                allowfullscreen="allowfullscreen"
                                allow="fullscreen">
                            </iframe>
                        </div>
                    </div>
                    <div class="buttom-blur"></div>

                </div>
                <div class="quiz-class" *ngIf="list_selected.type=='quiz'">
                    <div class="container-sm">
                        <div class="modal-header">
                            <h5 class="modal-title">Quiz :
                                {{quiz_data.title}}</h5>
                            <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button> -->
                        </div>
                        <div class="head2" *ngIf="quize_start">
                            <div
                                style="float: right;">({{quiz_data.quiz.length}}
                                คะแนน)</div>{{quiz_data.quiz.length}}
                            คำถาม
                        </div>
                        <div class="head2" *ngIf="!quize_start">
                            <div style="float: right;">({{quiz_data.point}}
                                คะแนน)</div>
                            {{quiz_active}}/{{quiz_data.quiz.length}} ข้อ
                        </div>
                        <div class="modal-body">
                            <div style="width: 100%;" class="text-center"
                                *ngIf="quize_start">
                                <button class="btn btn-info mx-auto"
                                    (click)="start_quiz_click()"><img
                                        src="../../assets/images/icon-play.png"
                                        style="
                                        width: 24px; position: absolute;"> <span
                                        style="padding-left: 30px;">เริ่มทำแบบทดสอบ</span></button>
                            </div>

                            <div [hidden]="quiz_show_finish||quize_start">
                                <ngb-carousel #carousel
                                    [showNavigationArrows]="false"
                                    [showNavigationIndicators]="false"
                                    (slide)="quizonSlide($event)"
                                    animation="true"
                                    loop="false" [interval]="10000000">
                                    <ng-template ngbSlide *ngFor="let quiz of
                                        quiz_data.quiz;let i= index">
                                        <div class="quiz_div">
                                            <div>{{i+1}} : {{quiz.title}}</div>
                                            <ul class="mt-sm">
                                                <li *ngFor="let ans of
                                                    quiz.options;let j=
                                                    index">
                                                    <div style="display:
                                                        inline-flex;width:
                                                        100%;">
                                                        <input type="radio"
                                                            [disabled]="quiz.disable"
                                                            style="margin-top:
                                                            6px;"
                                                            id="id{{i}}{{j}}"
                                                            [(ngModel)]="quiz.ans"
                                                            (change)="quize_change(i,j,ans)"
                                                            value="{{j}}"
                                                            [value]="j"
                                                            name="ans{{i}}"
                                                            [name]="'ans'+i">
                                                        <label
                                                            [ngClass]="{'c_true':
                                                            ans.c_true,'c_false':ans.c_false}"
                                                            style="margin-left:
                                                            10px;margin-bottom: 0px;
                                                            cursor: pointer;
                                                            width:
                                                            calc(100% - 25px);"
                                                            for="id{{i}}{{j}}">
                                                            {{ans.title}}
                                                        </label>
                                                    </div>

                                                </li>

                                            </ul>
                                        </div>
                                    </ng-template>
                                </ngb-carousel>
                                <hr>
                                <button class="btn btn-primary btn-rounded
                                    btn-sm" style="float: right;"
                                    [hidden]="quiz_finish"
                                    (click)="next_quiz(carousel)">next <img
                                        src="../../assets/images/Icon_feather-arrow-left-circle.png"
                                        style="width: 17px;"></button>
                                <button class="btn btn-success btn-rounded
                                    btn-sm" [hidden]="!(quiz_finish)"
                                    [disabled]="!quiz_select_all()"
                                    style="float: right;"
                                    (click)="quiz_finish_click(carousel,true)">
                                    เสร็จสิ้น </button>

                                <button class="btn btn-primary btn-rounded
                                    btn-sm" [hidden]="quiz_first"
                                    style="float:left;"
                                    (click)="back_quiz(carousel)"><img
                                        src="../../assets/images/back.png"
                                        style="width:
                                        17px;">
                                    Prev</button>
                            </div>
                            <div [hidden]="!quiz_show_finish">
                                <h5 style="margin:100px 0px;text-align:
                                    center;">
                                    <div>
                                        ทำถูก {{quiz_sum_score}} ข้อ
                                    </div>
                                    <div>
                                        จากทั้งหมด {{quiz_data.quiz.length}} ข้อ
                                    </div>
                                </h5>

                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="list_selected.type=='info'" style="margin-top:
                    20px;">
                    <div style="float: right;
                        font-weight: bold;
                        margin-right: 11px;">
                        <button [disabled]="pageVariable==1" class="btn btn-sm
                            btn-rounded back bg-success text-white"
                            (click)="carousel_info.prev()">
                            <img
                                src="../../assets/images/Icon_ionic-ios-arrow-back-1.png">
                            Prev
                        </button>
                        <button
                            [disabled]="pageVariable==list_selected.info.info_data.length"
                            class="btn btn-sm btn-rounded next bg-success
                            text-white " style="margin-left: 20px;"
                            (click)="carousel_info.next()">Next <img
                                src="../../assets/images/Icon_ionic-ios-arrow-back2.png">
                        </button>
                    </div>
                    <div>
                        {{pageVariable}}/{{list_selected.info.info_data.length}}
                    </div>

                </div>
                <div *ngIf="list_selected.type=='canva'" style="margin-top:
                    20px;">
                    <div style="float: right;
                        font-weight: bold;
                        margin-right: 11px;">
                        <!-- <button [disabled]="pageVariable==1" class="btn  btn-sm btn-rounded back bg-success text-white"
                            (click)="carousel_info.prev()">
                            <img src="../../assets/images/Icon ionic-ios-arrow-back-1.png"> Prev
                        </button>
                        <button [disabled]="pageVariable==list_selected.info.info_data.length"
                            class="btn btn-sm btn-rounded next bg-success text-white " style="margin-left: 20px;"
                            (click)="carousel_info.next()">Next <img
                                src="../../assets/images/Icon ionic-ios-arrow-back2.png">
                        </button> -->
                    </div>
                    <!-- <div>
                        {{pageVariable}}/{{list_selected.info.info_data.length}}
                    </div> -->
                </div>
                <!-- <div class="footer-info" *ngIf="list_selected.type=='info'">
                    {{pageVariable}}/{{list_selected.info.info_data.length}}
                    <div class="pull-right-info">
                        <div class="back_info" (click)="carousel_info.prev()">
                            <img src="../../assets/images/Icon awesome-arrow-alt-circle-right-1.png"
                                style="width: 20px;" alt=""> Back
                        </div>
                        <div class="next_info" (click)="carousel_info.next()">
                            <img src="../../assets/images/Icon awesome-arrow-alt-circle-right.png" style="width: 20px;"
                                alt=""> Next
                        </div>
                    </div>
                </div> -->
                <div class="pdf-viewer" [hidden]="!(list_selected.type=='pdf')"
                    [style.height.px]="pdf_height">
                    <!-- <div class="header-pdf">
                        {{title}}
                    </div> -->
                    <pdf-viewer [src]="pdfSrc" #pdfviewer
                        (on-progress)="zomechange($event)"
                        [(page)]="pageVariable"
                        [zoom-scale]="'page-fit'" style="display: block;"
                        [show-all]="false"
                        (after-load-complete)="afterLoadComplete($event)"
                        [autoresize]="true" [render-text]="true">
                    </pdf-viewer>
                </div>
                <!-- <div class="footer-pdf" [hidden]="!(list_selected.type=='pdf')">
                    {{pageVariable}}/{{totalPages}}
                    <div class="pull-right-pdf">
                        <div class="back_pdf" (click)="prev_pdf()">
                            <img src="../../assets/images/Icon awesome-arrow-alt-circle-right-1.png"
                                style="width: 20px;" alt=""> Back
                        </div>
                        <div class="next_pdf" (click)="next_pdf()">
                            <img src="../../assets/images/Icon awesome-arrow-alt-circle-right.png" style="width: 20px;"
                                alt=""> Next
                        </div>
                    </div>
                </div> -->
                <div [hidden]="!(list_selected.type=='pdf')">
                    <div style="float: right;
                        font-weight: bold;
                        margin-right: 11px;">
                        <button class="btn btn-sm btn-rounded back bg-success
                            text-white" (click)="prev_pdf()">
                            <img
                                src="../../assets/images/Icon_ionic-ios-arrow-back-1.png">
                            Prev
                        </button>

                        <button class="btn btn-sm btn-rounded next bg-success
                            text-white" style="margin-left: 20px;"
                            (click)="next_pdf()">Next <img
                                src="../../assets/images/Icon_ionic-ios-arrow-back2.png">
                        </button>
                    </div>
                    <div>
                        {{pageVariable}}/{{totalPages}}
                    </div>
                </div>
                <div style="padding:56.25% 0 0 0;position:relative;"
                    [hidden]="!(list_selected.type=='video')">
                    <iframe #iframe (document:mousemove)="mouseMove($event)"
                        style="position:absolute;top:0;left:0;width:100%;"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen [src]="urlSafe"></iframe>
                </div>
                <div class="btn-re">
                    <button class="btn  btn-yellow btn-rounded btn-sm in-btn"
                        [hidden]="!(list_selected.type=='video')"
                        (click)="refreshVideo()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16"
                            height="16" fill="currentColor"
                            class="bi bi-bootstrap-reboot svg-custom"
                            viewBox="0 0 16 16">
                            <path
                                d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.8 6.8 0 0 0 1.16 8z" />
                            <path
                                d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324z" />
                        </svg>
                        <span style="margin-right: 8px;">รีเฟรชวีดีโอ</span>
                    </button>
                </div>
                <div class="back-video">
                    <!-- <div class="btn-control">//////
                        <button class="btn  btn-sm btn-rounded back">
                            <img src="../../assets/images/Icon ionic-ios-arrow-back-1.png"> Prev</button>
                        <button class="btn btn-sm next ">Next <img
                                src="../../assets/images/Icon ionic-ios-arrow-back2.png"> </button>
                    </div> -->
                    <div class="tab-detail">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item topic-buttom">
                                <a class="nav-link " id="topic-tab" #topic
                                    data-toggle="tab" href="#topic" role="tab"
                                    aria-controls="topic"
                                    aria-selected="false">เนื้อหา</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link active" id="home-tab"
                                    data-toggle="tab" href="#home" role="tab"
                                    aria-controls="home"
                                    aria-selected="true">รายละเอียด</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="profile-tab"
                                    data-toggle="tab" href="#profile" role="tab"
                                    aria-controls="profile"
                                    aria-selected="false">วิทยากร</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="contact-tab"
                                    data-toggle="tab" href="#contact" role="tab"
                                    aria-controls="contact"
                                    aria-selected="false">ภาพถ่าย</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade topic topic-buttom-detail"
                                id="topic" role="tabpanel"
                                aria-labelledby="topic-tab">
                                <div class="head1">
                                    <div class="part">
                                        <span>Course content</span>
                                        <div class="right-head float-right">
                                            {{data.detail.percen}}% <div
                                                class="process">
                                                <div class="persen"
                                                    [ngStyle]="{'width.%':data.detail.percen}"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion" id *ngFor="let list of
                                    data.list;let i= index">
                                    <div class="card">
                                        <div class="card-header" id="headingOne"
                                            (click)="toggle(i)"
                                            style="cursor: pointer;">
                                            <h2 class="mb-0">
                                                <button class="btn btn-link"
                                                    type="button">
                                                    <i class="icon-action fa
                                                        fa-chevron-down"
                                                        *ngIf="!list.isCollapsed"></i>
                                                    <i class="icon-action fa
                                                        fa-chevron-right"
                                                        *ngIf="list.isCollapsed"></i>
                                                    {{list.title}}.
                                                </button>
                                                <div class="right-head
                                                    float-right">
                                                    <!-- {{list.time}}  -->
                                                    <div class="process">
                                                        <div class="persen"
                                                            [style]="{'width':(list.count.finish/list.count.all*100)+'%'}">
                                                        </div>
                                                    </div>
                                                    {{list.count.finish+"/"+list.count.all}}
                                                </div>
                                            </h2>
                                        </div>

                                        <div *ngFor="let lession of
                                            list.lessions"
                                            #collapse="ngbCollapse"
                                            [(ngbCollapse)]="list.isCollapsed"
                                            class="collapse">
                                            <div class="card-body">
                                                <div class="part">
                                                    <img
                                                        src="../../assets/images/Icon_open-document.png">
                                                    <!-- <div class="float-right">
                                                        {{lession.time}}
                                                    </div> -->
                                                    <div class="topic-group">
                                                        {{lession.title}}

                                                    </div>
                                                </div>
                                                <div class="timeline">
                                                    <div class="row">
                                                        <div class="col-md-12
                                                            col-lg-12">
                                                            <div id="tracking">
                                                                <div
                                                                    class="tracking-list">
                                                                    <div
                                                                        *ngFor="let
                                                                        detail
                                                                        of
                                                                        lession.list;let
                                                                        number=
                                                                        index">
                                                                        <div
                                                                            class="selected"
                                                                            *ngIf="selected(detail)">
                                                                        </div>
                                                                        <div
                                                                            class="not-selected"
                                                                            *ngIf="!selected(detail)">
                                                                        </div>
                                                                        <div
                                                                            class="div-click"
                                                                            (click)="goto_list(detail)">
                                                                        </div>
                                                                        <div
                                                                            class="tracking-item
                                                                            "
                                                                            [ngClass]="{'procress-item':
                                                                            detail.status!=0}">
                                                                            <div
                                                                                class="tracking-icon
                                                                                status-intransit
                                                                                "
                                                                                [ngClass]="{'success':
                                                                                detail.status==2,'procress':detail.procress==1}">
                                                                                <i
                                                                                    class="fas
                                                                                    fa-check"
                                                                                    *ngIf="detail.status==2"></i>
                                                                                <span
                                                                                    *ngIf="detail.status!=2">
                                                                                    {{number+1}}
                                                                                </span>
                                                                            </div>
                                                                            <div
                                                                                *ngIf="detail.type=='video'">
                                                                                <div
                                                                                    class="img">
                                                                                    <img
                                                                                        src="../../assets/images/Icon_awesome-video.png">
                                                                                </div>
                                                                                <div
                                                                                    class="tracking-date">
                                                                                    <img
                                                                                        *ngIf="detail.camera==1"
                                                                                        src="../../assets/images/Icon_ionic-ios-camera-1.png"
                                                                                        ngbPopover="You  see,
                                                                                        I
                                                                                        show
                                                                                        up
                                                                                        on
                                                                                        hover!"
                                                                                        triggers="mouseenter:mouseleave"
                                                                                        popoverTitle="Pop
                                                                                        title"
                                                                                        width="18">
                                                                                    <img
                                                                                        *ngIf="detail.camera==2"
                                                                                        src="../../assets/images/Icon_ionic-ios-camera.png"
                                                                                        ngbPopover="You
                                                                                        see,
                                                                                        I
                                                                                        show
                                                                                        up
                                                                                        on
                                                                                        hover!"
                                                                                        triggers="mouseenter:mouseleave"
                                                                                        popoverTitle="Pop
                                                                                        title"
                                                                                        width="18">
                                                                                    <img
                                                                                        *ngIf="detail.quiz==2"
                                                                                        class="qu_icon"
                                                                                        style="margin-left:
                                                                                        3px;"
                                                                                        src="../../assets/images/quiz-icon-2.png"
                                                                                        ngbPopover="You
                                                                                        see,
                                                                                        I
                                                                                        show
                                                                                        up
                                                                                        on
                                                                                        hover!"
                                                                                        triggers="mouseenter:mouseleave"
                                                                                        popoverTitle="Pop
                                                                                        title"
                                                                                        width="18">
                                                                                    <img
                                                                                        class="qu_icon"
                                                                                        *ngIf="detail.quiz==1"
                                                                                        src="../../assets/images/quiz-icon-2.png"
                                                                                        ngbPopover="You
                                                                                        see,
                                                                                        I
                                                                                        show
                                                                                        up
                                                                                        on
                                                                                        hover!"
                                                                                        triggers="mouseenter:mouseleave"
                                                                                        popoverTitle="Pop
                                                                                        title"
                                                                                        width="18">
                                                                                </div>
                                                                                <div
                                                                                    class="tracking-content">
                                                                                    {{detail.video.title}}
                                                                                    <span>{{detail.video.time}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                *ngIf="detail.type=='pdf'">
                                                                                <div
                                                                                    class="img">
                                                                                    <img
                                                                                        src="../../assets/images/Icon_metro-file-pdf.png">
                                                                                </div>
                                                                                <div
                                                                                    class="tracking-content">
                                                                                    {{detail.pdf.title}}
                                                                                    <span>{{detail.pdf.pdf_data.length}}
                                                                                        pages
                                                                                        /
                                                                                        {{detail.pdf.time}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                *ngIf="detail.type=='info'">
                                                                                <div
                                                                                    class="img">
                                                                                    <img
                                                                                        src="../../assets/images/Icon_ionic-md-images.png">
                                                                                </div>
                                                                                <div
                                                                                    class="tracking-content">
                                                                                    {{detail.info.title}}
                                                                                    <span>{{detail.info.info_data.length}}
                                                                                        images
                                                                                        /
                                                                                        {{detail.info.time}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                *ngIf="detail.type=='canva'">
                                                                                <div
                                                                                    class="img">
                                                                                    <img
                                                                                        src="../../assets/images/Icon_ionic-md-images.png">
                                                                                </div>
                                                                                <div
                                                                                    class="tracking-content">
                                                                                    {{detail.canva.title}}
                                                                                    <span>
                                                                                        {{detail.canva.time}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                *ngIf="detail.type=='test'">
                                                                                <div
                                                                                    class="img">
                                                                                    <img
                                                                                        src="../../assets/images/Icon_metro-question.png">
                                                                                </div>
                                                                                <div
                                                                                    class="tracking-content">
                                                                                    {{detail.exam.title}}
                                                                                    <span>{{detail.exam.count}}
                                                                                        questions
                                                                                        /
                                                                                        {{detail.exam.time}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                *ngIf="detail.type=='quiz'">
                                                                                <div
                                                                                    class="img">
                                                                                    <img
                                                                                        src="../../assets/images/quiz-icon-2.png">
                                                                                </div>
                                                                                <div
                                                                                    class="tracking-content">
                                                                                    {{detail.exam.title}}
                                                                                    <span>{{detail.exam.count}}
                                                                                        questions
                                                                                        /
                                                                                        {{detail.exam.time}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <!-- <div *ngIf="detail.type=='quiz'">
                                                                                <div  class="img">
                                                                                    <img
                                                                                        src="../../assets/images/Icon metro-question.png">
                                                                                </div>
                                                                                <div  class="tracking-content">Quiz
                                                                                    <span>5 questions / 10 min</span>
                                                                                </div>
                                                                            </div> -->
                                                                            <!-- <div *ngIf="detail.type=='take_picture'">
                                                                                <div class="img">
                                                                                    <img
                                                                                        src="../../assets/images/Icon awesome-video.png">
                                                                                </div>
                                                                                <div class="tracking-content">ถ่ายรูป
                                                                                   <span>5 questions / 10 min</span> 
                                                                                </div>
                                                                            </div> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="tab-pane fade show active details"
                                id="home" role="tabpanel"
                                aria-labelledby="home-tab">
                                <h3>
                                    Introduction
                                </h3>
                                <div>
                                    <div [innerHTML]="data.detail.advice">
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="profile"
                                role="tabpanel" aria-labelledby="profile-tab">
                                <div class="tutor">
                                    <div class="avatar">
                                        <img
                                            src="../../assets/images/profile-2.png"
                                            [src]="api.main_url()+ 'api/'+data.detail.lecturer.avata">
                                    </div>
                                    <div class="content">
                                        <h2>
                                            {{data.detail.lecturer.name}}
                                        </h2>
                                        <span>{{data.detail.lecturer.rank}}</span>
                                        <div
                                            [innerHTML]="data.detail.lecturer.bio">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="contact"
                                role="tabpanel" aria-labelledby="contact-tab">
                                <div class="picture-capture">
                                    <ul class="list-group"
                                        *ngIf="data.take_picture.length>0">
                                        <li class="list-group-item" *ngFor="let
                                            pic of data.take_picture">
                                            <div class="img">
                                                <img
                                                    src="../../assets/images/self2.png"
                                                    [src]="api.img_url+ 'take_photo/'+pic.img"
                                                    style="width: 90px;">
                                            </div>
                                            <div class="content">
                                                <div class="d-flex w-100
                                                    justify-content-between">
                                                    <div
                                                        class="mb-0">{{pic.title}}</div>
                                                </div>
                                                <p
                                                    class="mb-0">{{get_time_text2(pic.time)}}</p>
                                                <small> ถ่ายเมื่อ
                                                    {{get_text_datetime(pic.date)}}</small>
                                            </div>
                                        </li>
                                    </ul>
                                    <div style="width: 100%; padding: 50px 0px
                                        ;text-align: center;"
                                        *ngIf="data.take_picture.length==0">
                                        ยังไม่มีภาพถ่าย
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="listvideo col">
                <div class [className]="overflowscallright ?
                    'overflowscallright' : ''">
                    <div class="head1">
                        <div class="part">
                            <span>Course content</span>
                            <div class="right-head float-right">
                                {{data.detail.percen}}% <div class="process">
                                    <div class="persen"
                                        [ngStyle]="{'width.%':data.detail.percen}"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion" id *ngFor="let list of
                        data.list;let i= index">
                        <div class="card">
                            <div class="card-header" id="headingOne"
                                (click)="toggle(i)" style="cursor: pointer;">
                                <h2 class="mb-0">
                                    <button class="btn btn-link" type="button">
                                        <i class="icon-action fa
                                            fa-chevron-down"
                                            *ngIf="!list.isCollapsed"></i>
                                        <i class="icon-action fa
                                            fa-chevron-right"
                                            *ngIf="list.isCollapsed"></i>
                                        {{list.title}}.
                                    </button>

                                    <div class="right-head float-right">
                                        <!-- {{list.time}}  -->
                                        <div class="process">
                                            <div class="persen"
                                                [style]="{'width':(list.count.finish/list.count.all*100)+'%'}"></div>
                                        </div>
                                        {{list.count.finish+"/"+list.count.all}}
                                    </div>
                                </h2>
                            </div>

                            <div *ngFor="let lession of list.lessions"
                                #collapse="ngbCollapse"
                                [(ngbCollapse)]="list.isCollapsed"
                                class="collapse">
                                <div class="card-body">
                                    <div class="part">
                                        <img
                                            src="../../assets/images/Icon_open-document.png">
                                        <!-- <div class="float-right">
                                            {{lession.time}}
                                        </div> -->
                                        <div class="topic-group">
                                            {{lession.title}}

                                        </div>

                                    </div>
                                    <div class="timeline">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12">
                                                <div id="tracking">
                                                    <div class="tracking-list">
                                                        <div *ngFor="let detail
                                                            of lession.list;let
                                                            number= index"
                                                            [disablePopover]="detail.camera!=1&&detail.quiz!=1"
                                                            ngbPopover="มีการถ่ายรูปหรือทำแบบทดสอบระหว่างดูวีดีโอ"
                                                            triggers="mouseenter:mouseleave">
                                                            <div
                                                                class="selected"
                                                                *ngIf="selected(detail)"
                                                                [ngClass]="{'hover_list':
                                                                detail.changeText}">
                                                                {{detail.changeText}}
                                                            </div>
                                                            <div
                                                                class="not-selected"
                                                                *ngIf="!selected(detail)"
                                                                [ngClass]="{'hover_list':
                                                                detail.changeText}">
                                                            </div>
                                                            <div
                                                                class="div-click"
                                                                (click)="goto_list(detail)"
                                                                (mouseover)="detail.changeText=true"
                                                                (mouseout)="detail.changeText=false">
                                                            </div>

                                                            <div
                                                                class="tracking-item
                                                                "
                                                                [ngClass]="{'procress-item':
                                                                detail.status!=0}">
                                                                <div
                                                                    class="tracking-icon
                                                                    status-intransit
                                                                    "
                                                                    [ngClass]="{'success':
                                                                    detail.status==2,'procress':detail.procress==1}">
                                                                    <i
                                                                        class="fas
                                                                        fa-check"
                                                                        *ngIf="detail.status==2"></i>
                                                                    <span
                                                                        *ngIf="detail.status!=2">
                                                                        {{number+1}}
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    *ngIf="detail.type=='video'">
                                                                    <div
                                                                        class="img">
                                                                        <img
                                                                            src="../../assets/images/Icon_awesome-video.png">
                                                                    </div>
                                                                    <div
                                                                        class="tracking-date">
                                                                        <img
                                                                            *ngIf="detail.camera==1"
                                                                            src="../../assets/images/Icon_ionic-ios-camera-1.png"
                                                                            width="18">
                                                                        <!-- <div style="position: absolute;z-index: 555;font-size: 15px;">มีการถ่ายภาพระหว่างเรียน</div> -->
                                                                        <img
                                                                            *ngIf="detail.camera==2"
                                                                            src="../../assets/images/Icon_ionic-ios-camera.png"
                                                                            width="18">
                                                                        <img
                                                                            *ngIf="detail.quiz==2"
                                                                            class="qu_icon"
                                                                            style="margin-left:
                                                                            3px;"
                                                                            src="../../assets/images/quiz-icon-2.png"
                                                                            width="18">
                                                                        <img
                                                                            class="qu_icon"
                                                                            *ngIf="detail.quiz==1"
                                                                            src="../../assets/images/quiz-icon-2.png"
                                                                            width="18">
                                                                    </div>
                                                                    <div
                                                                        class="tracking-content">
                                                                        {{detail.video.title}}
                                                                        <span>{{detail.video.time}}</span>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    *ngIf="detail.type=='pdf'">
                                                                    <div
                                                                        class="img">
                                                                        <img
                                                                            src="../../assets/images/Icon_metro-file-pdf.png">
                                                                    </div>
                                                                    <div
                                                                        class="tracking-content">{{detail.pdf.title}}
                                                                        <span>{{detail.pdf.pdf_data.length}}
                                                                            pages
                                                                            /
                                                                            {{detail.pdf.time}}</span>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    *ngIf="detail.type=='info'">
                                                                    <div
                                                                        class="img">
                                                                        <img
                                                                            src="../../assets/images/Icon_ionic-md-images.png">
                                                                    </div>
                                                                    <div
                                                                        class="tracking-content">{{detail.info.title}}
                                                                        <span>{{detail.info.info_data.length}}
                                                                            images
                                                                            /
                                                                            {{detail.info.time}}</span>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    *ngIf="detail.type=='canva'">
                                                                    <div
                                                                        class="img">
                                                                        <img
                                                                            src="../../assets/images/Icon_ionic-md-images.png">
                                                                    </div>
                                                                    <div
                                                                        class="tracking-content">{{detail.canva.title}}
                                                                        <span>
                                                                            {{detail.canva.time}}</span>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    *ngIf="detail.type=='test'">
                                                                    <div
                                                                        class="img">
                                                                        <img
                                                                            src="../../assets/images/Icon_metro-question.png">
                                                                    </div>
                                                                    <div
                                                                        class="tracking-content">{{detail.exam.title}}
                                                                        <span>{{detail.exam.count}}
                                                                            questions
                                                                            /
                                                                            {{detail.exam.time}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    *ngIf="detail.type=='quiz'">
                                                                    <div
                                                                        class="img">
                                                                        <img
                                                                            src="../../assets/images/quiz-icon-2.png">
                                                                    </div>
                                                                    <div
                                                                        class="tracking-content">{{detail.exam.title}}
                                                                        <span>{{detail.exam.count}}
                                                                            questions
                                                                            /
                                                                            {{detail.exam.time}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- <div *ngIf="detail.type=='quiz'">
                                                                    <div  class="img">
                                                                        <img
                                                                            src="../../assets/images/Icon metro-question.png">
                                                                    </div>
                                                                    <div  class="tracking-content">Quiz
                                                                        <span>5 questions / 10 min</span>
                                                                    </div>
                                                                </div> -->
                                                                <!-- <div *ngIf="detail.type=='take_picture'">
                                                                    <div class="img">
                                                                        <img
                                                                            src="../../assets/images/Icon awesome-video.png">
                                                                    </div>
                                                                    <div class="tracking-content">ถ่ายรูป
                                                                       <span>5 questions / 10 min</span> 
                                                                    </div>
                                                                </div> -->
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <ng-template #finish_video let-modal class>

            <div class="modal-body finish">

                <h3 style="font-size: 20px;">
                    จบบทเรียน
                </h3>
                <h5 style="font-size: 18px;">
                    {{title}}
                </h5>
                <button class="btn orn"
                    (click)="next_btn(modal);modal.dismiss('Cross click')">
                    <img
                        src="../../assets/images/Icon_awesome-angle-double-right.png"
                        alt> {{title_next}}
                </button>
                <button class="btn light" (click)="play_agen(modal)">
                    <img src="../../assets/images/Icon open-reload.png" alt>
                    เรียนซ้ำอีกครั้ง
                </button>

                <button class="btn close-custom" (click)="close_modal(modal)"
                    style="margin-top: 15px;">

                    ปิดออก
                </button>
            </div>

        </ng-template>
        <ng-template #take_photo_modal let-modal class>

            <div class="modal-body camera">
                <!-- <div class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <img src="../../assets/images/Icon metro-cross.png">
                </div> -->
                <div class="row">
                    <div class="col col-md-12" *ngIf="show_camera">
                        <div style="padding: 5px;">
                            {{time_take_video}}
                            {{list_selected.video.title}}
                        </div>
                        <div class="phone">
                            <!-- <div class="mic"></div> -->
                            <div class="picture">
                                <!-- <img src="../../assets/images/03.png"> -->
                                <!-- <video id="video" #video width="640" height="480" autoplay></video> -->
                                <div
                                    style="width: 230px;  border-radius: 10px;    overflow: hidden;">
                                    <div style="margin:auto 0px;">
                                        <webcam [width]="230"
                                            [trigger]="triggerObservable"
                                            (imageCapture)="handleImage($event)"
                                            [allowCameraSwitch]="allowCameraSwitch"
                                            [switchCamera]="nextWebcamObservable"
                                            [videoOptions]="videoOptions"
                                            [imageQuality]="1"
                                            (cameraSwitched)="cameraWasSwitched($event)"
                                            (initError)="handleInitError($event)"></webcam>
                                    </div>
                                </div>
                                <!-- <button id="snap" (click)="capture()">Snap Photo</button>
                                <canvas #canvas id="canvas" width="640" height="480"></canvas> -->
                                <!-- <div class="refresh">
                                    <img src="../../assets/images/Icon material-refresh.png">
                                </div> -->
                                <div class="btn-capture"
                                    (click)="triggerSnapshot();">
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col  col-md-12" *ngIf="!show_camera">
                        <div class="display">
                            <!-- <div class="profile">
                                <span>
                                    ภาพถ่ายที่ใช้ยืนยันตัวตน
                                </span>
                                <img
                                    src="{{api.img_url}}take_photo/{{data.detail.kyc.profile}}">
                            </div> -->

                            <div class="profile">

                                <div class="snapshot" *ngIf="webcamImage">
                                    <img [src]="webcamImage.imageAsDataUrl" />
                                </div>
                                <img src="../../assets/images/no-avatar.png"
                                    *ngIf="!webcamImage">

                                <!-- <div class="aceb" *ngIf="percen_face>=60">
                                    ความเหมือน {{percen_face}}%
                                </div>
                                <div class="error" *ngIf="percen_face<20">
                                    ความเหมือน {{percen_face}}%
                                </div> -->
                            </div>
                            <!-- <div class="error-text" *ngIf="percen_face<20">
                                ต้องเหมือน 20% ขึ้นไป
                            </div> -->
                            <!-- <div class="picture">
                                <div class="snapshot" *ngIf="webcamImage">
                                    <img [src]="webcamImage.imageAsDataUrl" />
                                </div>
                                <img src="../../assets/images/itg-199.png" *ngIf="!webcamImage">
                            </div> -->
                            <!-- <img src="../../../../api/take_photo/{{data.detail.kyc.profile}}"> -->
                            <!-- <div style="text-align: center;font-size: 20px;"
                                [ngClass]="{'red': percen_face<60,'green':percen_face>=60}" *ngIf="webcamImage">
                                เหมือน {{percen_face}} %</div>
                            <div style="text-align: center;color: red;" *ngIf="webcamImage&&percen_face<60">ต้องได้ 60%
                                ขึ้นไป</div>
                            <span>
                                {{time_take_video}} {{list_selected.video.title}}
                            </span>
                            <span class="text-center press">
                                โปรดตรวจเช็คความคมชัดของภาพ<br />
                                ก่อนทำการบันทึกที่ปุ่มด้านล่าง
                            </span>
                            <span class="text-danger waning text-center">
                                หากกดบันทึกไปแล้ว <br />
                                จะไม่สามารถกลับมาแก้ไข
                            </span> -->
                            <!-- &&percen_face>=20 -->
                            <button class="btn btn-default btn-sm mr-sm"
                                [disabled]="!webcamImage"
                                (click)="show_camera=true">
                                ถ่ายภาพใหม่
                            </button>
                            <button class="btn btn-success btn-sm"
                                [disabled]="!webcamImage"
                                (click)="save_picture(modal)">
                                บันทึกภาพนี้
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </ng-template>
        <ng-template #quiz_modal let-modal>
            <div class="modal-header">
                <h5 class="modal-title">Quiz : {{quiz_data.title}}</h5>
                <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button> -->
            </div>
            <div class="head2" *ngIf="quize_start">
                <div style="float: right;">({{quiz_data.quiz.length}}
                    คะแนน)</div>{{quiz_data.quiz.length}}
                คำถาม
            </div>
            <div class="head2" *ngIf="!quize_start">
                <div style="float: right;">({{quiz_data.point}} คะแนน)</div>
                {{quiz_active}}/{{quiz_data.quiz.length}} ข้อ
            </div>
            <div class="modal-body">
                <div style="width: 100%;" class="text-center"
                    *ngIf="quize_start">
                    <button class="btn btn-info mx-auto"
                        (click)="start_quiz_click()"><img
                            src="../../assets/images/icon-play.png" style="
                            width: 24px; position: absolute;"> <span
                            style="padding-left: 30px;">เริ่มทำแบบทดสอบ</span></button>
                </div>

                <div [hidden]="quiz_show_finish||quize_start">
                    <ngb-carousel #carousel [showNavigationArrows]="false"
                        [showNavigationIndicators]="false"
                        (slide)="quizonSlide($event)" animation="true"
                        loop="false" [interval]="10000000">
                        <ng-template ngbSlide *ngFor="let quiz of
                            quiz_data.quiz;let i= index">
                            <div class="quiz_div">
                                <div>{{i+1}} : {{quiz.title}}</div>
                                <ul class="mt-sm">
                                    <li *ngFor="let ans of quiz.options;let j=
                                        index">
                                        <div style="display: inline-flex;width:
                                            100%;">
                                            <input type="radio"
                                                [disabled]="quiz.disable"
                                                style="margin-top: 6px;"
                                                id="id{{i}}{{j}}"
                                                [(ngModel)]="quiz.ans"
                                                (change)="quize_change(i,j,ans)"
                                                value="{{j}}" [value]="j"
                                                name="ans{{i}}"
                                                [name]="'ans'+i">
                                            <label [ngClass]="{'c_true':
                                                ans.c_true,'c_false':ans.c_false}"
                                                style="margin-left: 10px;
                                                cursor: pointer; width:
                                                calc(100% - 25px);"
                                                for="id{{i}}{{j}}">
                                                {{ans.title}}
                                            </label>
                                        </div>

                                    </li>

                                </ul>
                            </div>
                        </ng-template>
                    </ngb-carousel>
                    <hr>
                    <button class="btn btn-primary btn-rounded btn-sm"
                        style="float: right;" [hidden]="quiz_finish"
                        (click)="next_quiz(carousel)">next <img
                            src="../../assets/images/Icon
                            feather-arrow-left-circle.png"
                            style="width: 17px;"></button>
                    <button class="btn btn-success btn-rounded btn-sm"
                        [hidden]="!(quiz_finish)"
                        [disabled]="!quiz_select_all()" style="float: right;"
                        (click)="quiz_finish_click(carousel,false)">
                        เสร็จสิ้น </button>

                    <button class="btn btn-primary btn-rounded btn-sm"
                        [hidden]="quiz_first" style="float:left;"
                        (click)="back_quiz(carousel)"><img
                            src="../../assets/images/back.png" style="width:
                            17px;">
                        Prev</button>
                </div>
                <div [hidden]="!quiz_show_finish">
                    <h5 style="margin:100px 0px;text-align: center;">
                        <div>
                            ทำถูก {{quiz_sum_score}} ข้อ
                        </div>
                        <div>
                            จากทั้งหมด {{quiz_data.quiz.length}} ข้อ
                        </div>
                    </h5>
                    <div class="text-center">
                        <button type="button" class="btn btn-info btn-sm
                            btn-rounded"
                            (click)="modal.close('Close click')">ปิดออก</button>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-light btn-sm btn-rounded"
                    (click)="modal.close('Close click')">ปิดออก</button>
            </div> -->
        </ng-template>
        <ng-template #photo_viewer let-modal>
            <div class="modal-body" style="padding:5px 5px 5px 5px;">
                <div class="close-btn" aria-label="Close"
                    (click)="modal.dismiss('Cross click')">
                    <img src="../../assets/images/Icon metro-cross.png">
                </div>
                <div style="max-width: 100%;">
                    <img src [src]="img_viewer" style="width: 100%;" alt>
                </div>
            </div>
        </ng-template>
        <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
</div>
<script>
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
        var currentScrollPos = window.pageYOffset;
        consoel.log(currentScrollPos);
        if (prevScrollpos > currentScrollPos) {
        } else {
        }
        prevScrollpos = currentScrollPos;
    }
</script>
<app-footer></app-footer>