<app-header2></app-header2>

<div id="body" class="content">
  <div class="header-page">
    <div class="p-4 pl-5">
      <h1 class="container p-30">
        คู่มือการใช้งาน
      </h1>
    </div>
  </div>

  <div class="container my-5">
    <div class="row">
      <div class="col-12">
        <img src="../../assets/images/StepManual.png" width="100%">
      </div>
      <div class="col-12">
        <p class="h4 my-5">วิดีโอแนะนำการใช้งาน</p>
        <div>
          <iframe width="100%" height="650" src="https://www.youtube.com/embed/F1T6k1inzHE" title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer2></app-footer2>
