import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectApiService } from './connect.api.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'elerning';
  time_logout = 1800;//วินาที
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    this.time_logout = 1800;//วินาที
  }
  constructor(public api: ConnectApiService, public router: Router) {

  }
  ngOnInit(): void {
    // setInterval(() => {
      // let d = new Date();
      // if (d.getMinutes() == 25 && d.getSeconds() == 0) {

      //   const swalWithBootstrapButtons = Swal.mixin({
      //     customClass: {
      //       confirmButton: 'btn btn-success ml-2',
      //       cancelButton: 'btn btn-dark ml-2'
      //     },
      //     buttonsStyling: false
      //   });
      //   swalWithBootstrapButtons.fire({
      //     title: 'อีก 5 นาทีระบบจะปิดปรับปรุงชั่วคราว ขออภัยในความไม่สะดวก',
      //     // text: "รอผลการอนุมัติภายใน 3 วันทำการระบบจะแจ้งผลการอนุมัติ และข้อมูลการเข้าระบบไปยัง email ของท่าน",
      //     icon: 'info',
      //     showCancelButton: false,
      //     confirmButtonText: 'ตกลง',
      //     //                                                cancelButtonText: 'ปิดออก',
      //     reverseButtons: true
      //   }).then(function (result) {

      //   });
      // }
      // if (d.getMinutes() == 30 && d.getSeconds() == 0) {
      //   const swalWithBootstrapButtons = Swal.mixin({
      //     customClass: {
      //       confirmButton: 'btn btn-success ml-2',
      //       cancelButton: 'btn btn-dark ml-2'
      //     },
      //     buttonsStyling: false
      //   });
      //   swalWithBootstrapButtons.fire({
      //     title: 'ปิดระบบชั่วคราว จะกลับมาใช้ได้อีกครั้งในอีกไม่นาน ขออภัยในความไม่สะดวก',
      //     // text: "รอผลการอนุมัติภายใน 3 วันทำการระบบจะแจ้งผลการอนุมัติ และข้อมูลการเข้าระบบไปยัง email ของท่าน",
      //     icon: 'info',
      //     showCancelButton: false,
      //     confirmButtonText: 'ตกลง',
      //     //                                                cancelButtonText: 'ปิดออก',
      //     reverseButtons: true
      //   }).then(function (result) {

      //   });
      // }
      // if (d.getMinutes() >= 30 && d.getMinutes() <= 50) {
      //   setTimeout(() => {
      //     this.router.navigate(['/comingsoon']);
      //   }, 5000);
      // }

    // })
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    let time = null;
    time = setInterval(() => {
      if (this.time_logout > 0) {
        // console.log(this.time_logout);
        this.time_logout--;

        if (this.time_logout == 0) {
          // console.log("logout");
          this.logout();
        } else if (this.time_logout == 20) {
          // this.show_logout();
        }
      } else {
        clearInterval(time);
      }
    }, 1000);
  }
  logout() {
    localStorage.setItem('auth_token', null);
    this.api.storage_set("userdata", null);
    this.router.navigate(['/login']);
  }
}
