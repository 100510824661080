<app-header2></app-header2>

<div class="header-page content">
  <div class="container">
    <h1 class="text-white">
      {{_round.title}}
    </h1>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-12 py-3 mt-2">
      <label class="mx-2 meunu" [ngClass]="{'pick': page === 1 }" (click)="pickMenu(1)">รายละเอียด</label>
      <label class="mx-2 meunu" [ngClass]="{'pick': page === 2 }" (click)="pickMenu(2)">โครงสร้างหลักสูตร</label>
    </div>
  </div>
</div>


<div class="desktop mobile mb-3">
  <div class="container">
    <div class="row row-course-list">
      <div class="col-sm-12 col-md-6 col-lg-6 pb-3" *ngIf="page == 1">
        <img class="img-courese"
          src="https://www.jurist.org/commentary/wp-content/uploads/sites/3/2019/03/cryptocurrency_1554065352.jpg"
          width="100%">
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="page == 1">
        <ul class="list-group">
          <li class="list-group-item">
            <div style="display: inline-flex;">
              <i class="far fa-clock pt-2"></i>
            </div>
            <div class="pl-3" style="display: inline-table;">
              <label class="fw-bolder">
                ระยะเวลารวม
              </label>
              <br>
              {{hr.hr | number}} ชั่วโมง {{hr.min | number}} นาที
            </div>
          </li>
          <li class="list-group-item">
            <div style="display: inline-flex;">
              <i class="fas fa-database pt-2"></i>
            </div>
            <div class="pl-3 fw-bolder" style="display: inline-flex;">
              หมวดหมู่
            </div>
            <div>
              <ul>
                <li *ngFor="let s of _side" class="py-1">
                  - {{s.title}}
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-12 mt-4" *ngIf="page == 1">
        <span [innerHtml]="_round.details"></span>
        <!-- <div class="mb-4 pb-2">
          <h5>คำอธิบายหลักสูตร</h5>
          <p>
            ทบทวนความรู้พื้นฐานก่อนเทรดหุ้นออนไลน์ พร้อมแนะนำฟังก์ชั่นการใช้งานต่างๆ ใน Settrade Streaming
            เพื่อเป็นตัวช่วยในการลงทุนอย่างมั่นใจ เข้าใจและสามารถเทรดหุ้นออนไลน์ได้ด้วยตนเองผ่าน Settrade Streaming
            ตลอดจนสามารถใช้ฟังก์ชันเสริม เช่น DCA Order และ Stock Screener
            เพื่อวางกลยุทธ์การลงทุนของตนเองให้มีประสิทธิภาพมากยิ่งขึ้น
          </p>
        </div>
        <div class="mb-4 pb-2">
          <h5>วัตถุประสงค์</h5>
          <p>
            เข้าใจและสามารถเทรดหุ้นออนไลน์ได้ด้วยตนเองผ่าน Settrade Streaming ตลอดจนสามารถใช้ฟังก์ชันเสริม เช่น DCA
            Order และ Stock Screener เพื่อวางกลยุทธ์การลงทุนของตนเองให้มีประสิทธิภาพมากยิ่งขึ้น
          </p>
        </div>
        <div class="mb-4 pb-2">
          <h5>หลักสูตรนี้เหมาะสำหรับ</h5>
          <p>
            นักลงทุนและผู้สนใจทั่วไป
          </p>
        </div>
        <div class="mb-4 pb-2">
          <h5>หลักสูตรแนะนำก่อนเรียน</h5>
          <p>
            EQD1002: ลงทุนในหุ้นออนไลน์ ง่ายคลิกเดียว
          </p>
        </div>
        <div class="mb-4 pb-2">
          <h5>การรับวุฒิบัตร</h5>
          <p>
            เข้าเรียนไม่น้อยกว่า 80% ของเวลาเรียนทั้งหมด และสอบผ่านไม่น้อยกว่า 70% ของคะแนนโดยรวมทั้งหมด
          </p>
        </div>
        <div class="mb-4 pb-2">
          <h5>รายละเอียดเพิ่มเติม</h5>
          <p>
            ดูได้ที่แอพพลิเคชั่นของ AIMC ที่ <a href="https://www.aimc.or.th/applications" target="blank">https://www.aimc.or.th/applications</a>
          </p>
        </div> -->
      </div>
      <div class="col-12 mt-4" *ngIf="page == 2">
        <table class="table">
          <tr class="hr20">
            <td>หัวข้อบังคับ</td>
            <td align="right">20 ชั่วโมง</td>
          </tr>
          <tr *ngFor="let c of _courses">
            <td>
              <label class="fw-light">ICR-001</label>
              <br>
              <label class="fw-bolder">{{c.title}}</label>
              <br>
              <label>จรรยาบรรณ</label>
            </td>
            <td align="right" class="hr">{{c.duration|number:'1.1-2'}} ชม.</td>
          </tr>
          <!-- <tr class="hr10">
            <td>หัวข้อบังคับ</td>
            <td align="right">10 ชั่วโมง</td>
          </tr>
          <tr *ngFor="let item of _courese">
            <td>
              <label class="fw-light">ICR-001</label>
              <br>
              <label class="fw-bolder">อาชญากรรมทางการเงิน และแนวทางป้องกัน</label>
              <br>
              <label>จรรยาบรรณ</label>
            </td>
            <td align="right" class="hr">5 ชม.</td>
          </tr> -->
        </table>
      </div>
    </div>
  </div>
</div>

<app-footer2></app-footer2>
