import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common'
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ConnectApiService } from '../connect.api.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent implements OnInit {
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  @ViewChild('take_photo_idcard') take_photoidcardmodalEle;
  @ViewChild('take_photoEle') take_photomodalEle;
  step = 1;
  show_camera = true;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];
  public webcamImage: WebcamImage = null;
  ut_id = '';
  topicid = '';
  uri = '';
  rt_id = '';
  // latest snapshot
  // latest snapshot
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  images = {
    idcard: null,
    ut_id: '',
    profile: null
  }
  constructor(private modalService: NgbModal, private route: ActivatedRoute, private api: ConnectApiService, private router: Router, private location: Location) { }

  ngOnInit(): void {
    this.ut_id = this.route.snapshot.paramMap.get("ut_id");
    this.topicid = this.route.snapshot.paramMap.get('topicid');
    this.uri = this.route.snapshot.paramMap.get('uri');
    this.rt_id = this.route.snapshot.paramMap.get('rt_id');
  }
  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }
  public triggerSnapshot(): void {
    this.trigger.next();
    this.show_camera = false;
  }
  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  handleInitError(e) { }
  save_idcard(modal) {
    this.images.idcard = this.webcamImage;
    this.step = 2;
    modal.dismiss();

  }

  save_people(modal) {
    this.images.profile = this.webcamImage;
    this.step = 3;
    modal.dismiss();

  }


  take_photo() {
    this.show_camera = true;
    this.webcamImage = null;
    this.modalService.open(this.take_photoidcardmodalEle, { ariaLabelledBy: 'modal-basic-title', size: 'sm', windowClass: "take_photo" }).result.then((result) => {

    }, (reason) => {

    });

  }
  take_photo_people() {
    this.show_camera = true;
    this.webcamImage = null;
    this.modalService.open(this.take_photomodalEle, { ariaLabelledBy: 'modal-basic-title', size: 'sm', windowClass: "take_photo" }).result.then((result) => {

    }, (reason) => {

    });

  }
  back() {
    this.location.back()
  }
  save() {
    this.api.postData("save_kyc2", { ut_id: this.ut_id, idcard: this.images.idcard._imageAsDataUrl, profile: this.images.profile._imageAsDataUrl }).then((res: any) => {
      console.log(res);
      // if (res.success - 0 == 1) {
        this.step = 4;
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
          title: 'ยืนยันตัวตนเรียบร้อย',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '<img src="../../assets/images/Icon awesome-angle-double-right.png" style="width: 15px;margin-top: -5px; margin-right: 5px;"> เข้าสู่บทเรียน',
          reverseButtons: false
        }).then((result) => {
          if (result.isConfirmed) {
            // console.log("back");
            // console.log('/topic/' + this.topicid + '/' + this.uri);
            this.router.navigate(['/topic/' + this.topicid + '/' + this.uri + "/" + this.rt_id]);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {

          }
        })
      // } else {
      //   this.api.log("มีปัญหาในการอัพโหลดรูปภาพ หรือรูปภาพขนาดเล็กเกินไป กรุณาลองใหม่อีกครั้ง");
      // }

    })
    setTimeout(() => {
      this.show_camera = true;
    }, 1000);
  }
}
