import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConnectApiService } from '../connect.api.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  Login: FormGroup;
  constructor(private _formBuilder: FormBuilder, public api: ConnectApiService, private router: Router) { }
  show_error = false;
  show_success = false;
  ngOnInit(): void {
    this.Login = this._formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13)]],
      type: ['sms', [Validators.required]]
    });
  }
  set_otp(code) {
    Swal.fire({
      allowOutsideClick: false,
      title: 'กรอกหมายเลข OTP ที่คุณได้รับ',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      showLoaderOnConfirm: true,
      preConfirm: (otp) => {
        this.api.postData("confirm_otp", { code: code, otp: otp }).then((res: any) => {
          if (res.flag) {
            this.router.navigate(['/newpass/' + code + "/" + otp]);
          } else {
            this.set_otp(code);
          }
        })
      }
    }).then((result) => {
      if (result.isConfirmed) {

      }
    })
  }
  login_func() {
    console.log(this.Login);
    if (this.Login.invalid) {
      return;
    }
    this.api.forgot(this.Login.value).then((res: any) => {
      console.log(res);
      if (res.flag - 0 == 1) {
        this.show_error = false;
        this.show_success = true;
        if (this.Login.value.type == 'email') {
          this.api.log("กรุณาตรวจสอบ Email เพื่อเปลี่ยนรหัสผ่านของคุณ");
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 5000);


        } else {
          this.set_otp(res.code);
        }

      } else if (res.flag - 0 == 2) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'กรุณารออีกอย่างน้อย 3 นาทีเพื่อส่งข้อความอีกครั้ง',
          // footer: '<a href="">Why do I have this issue?</a>'
        })
      } else {
        this.show_error = true;
        this.show_success = false;
        // this.api.error(res.error);
      }
    });

  }


}
