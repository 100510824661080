import { ConnectApiService } from './../connect.api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(public api : ConnectApiService) { }

  ngOnInit(): void {
    this.goTop()
    this.loadContact()
  }

  goTop(){
    window.scrollTo(0, 0);
  }

  _contact : any = ''
  loadContact(){
    this.api.getData_nologin('../Front_web/loadContact').then((res:any)=>{
      if(res){
        this._contact = res
      }
    })
  }

}
