import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectApiService } from '../connect.api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user = {
    cid: "",
    first_name: "",
    last_login: "",
    profile_url: '',
    last_name: "",
    phone: "",
    prefix: "",
    user_email: "",
    user_id: "",
    inhouse_name: '',
    in_h_id: ''
  };

  constructor(public api: ConnectApiService, public router: Router) { }
 
  ngOnInit(): void {
    this.load_profile();

  }
  logout() {
    localStorage.setItem('auth_token', null);
    this.api.storage_set("userdata", null);
    this.router.navigate(['/']);
  }

  load_profile() {
    this.api.getData('get_profile').then((res: any) => {
      console.log(res);
      if (res.flag) {
        this.user = res.data;
        if (this.user.profile_url == '') {
          this.user.profile_url = "../../assets/images/profile-2.png";
        }

      } else {
        this.router.navigate(['/login']);
      }
    })
  }
}
