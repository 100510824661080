<app-header2></app-header2>

<div id="body" class="content">
  <div class="header-page">
    <div class="p-4 pl-5">
      <h1 class="container p-30">
        เกี่ยวกับเรา
      </h1>
    </div>
  </div>

  <div class="container pb-5">
    <div class="row">
      <div class="col-12 pt-5">
        <div style="width: fit-content;display: contents;">
          <label class="fw-bold" style="font-size: 140px;line-height: 90px;color: #9100FF;">{{_anout.length}}</label>
        </div>
        <div class="ml-2"
          style="width: fit-content;display: inline-block;height: 115px;vertical-align: text-bottom;color: #147BD6;">
          <div class="fs-1 mt-2 fw-bold">
            จุดเด่น
          </div>
          <div class="fs-1 fw-bold">
            AIMC E-Learining
          </div>
        </div>
        <!-- <img src="../../assets/images/7.png" width="100%"> -->
      </div>
    </div>
    <div class="border-bottom my-4"></div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 my-3" *ngFor="let a of _anout;let i = index">
        <div class="card p-3 shadow-sm">
          <table>
            <tr>
              <td rowspan="2" width="10%" align="center" style="vertical-align: initial;">
                <label class="h2 number-pros text-white fw-bolder">
                  {{i+1}}
                </label>
              </td>
              <td class="pl-3 fw-bolder h5" width="90%">{{a.title}}</td>
            </tr>
            <tr>
              <td class="pl-3">
                <p>
                  {{a.content}}
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-12 mt-4">
        <h5 class="text-center">
          สมาคมบริษัทจัดการลงทุน
          |
          <a href="http://www.aimc.or.th " target="_blank" rel="noopener noreferrer">www.aimc.or.th </a>
          | Contact Center โทร.
          <a href="tel:+02-2264-0900">02-2264-0900</a>
        </h5>
      </div>
    </div>
  </div>
</div>

<app-footer2></app-footer2>
