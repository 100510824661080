<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center p-4">
        <span [innerHTML]="_footer.footer" class="p-footer"></span>
        <!-- <i class="h1 fas fa-arrow-alt-circle-up arrow-mb-md text-white" (click)="goTop()"></i> -->
      </div>
    </div>
    <!-- <i class="h1 fas fa-arrow-alt-circle-up arrow-lg text-white" style="position:relative;bottom: 10vh;left: 100%;"
      (click)="goTop()"></i> -->
  </div>

</div>