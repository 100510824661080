import { ConnectApiService } from './../connect.api.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import * as $ from 'jquery';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  courses = [];

  @ViewChild("video")
  public video: ElementRef;
  @ViewChild("canvas")
  public canvas: ElementRef;
  public captures: Array<any>;
  selectedFiles: FileList;
  agreecheck = false;
  message = '';
  user = {
    cid: "",
    first_name: "",
    last_login: "0000-00-00 00:00:00",
    profile_url: '',
    last_name: "",
    phone: "",
    prefix: "",
    user_email: "",
    user_id: "",
    inhouse_name: '',
    in_h_id: ''
  };
  url = '';
  profile: FormGroup;
  croppedImage: any = '';
  change_pass: FormGroup;
  imageChangedEvent: any = '';
  constructor(public api: ConnectApiService, private _formBuilder: FormBuilder,
    private modalService: NgbModal, public router: Router) {
    this.captures = [];
  }
  compressImage(src, newX, newY) {

    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }
  load_profile() {
    this.api.getData('get_profile').then((res: any) => {
      console.log(res);
      if (res.flag) {
        this.user = res.data;
        if (this.user.profile_url == '') {
          this.user.profile_url = "../../assets/images/profile-2.png";
        }

      } else {
        this.router.navigate(['/login']);
      }
    })
  }
  get_text_datetime(date) {
    return this.api.get_text_datetime(date);
  }
  get_text_date(date) {
    return this.api.get_text_date(date);
  }
  courses_not_exp = [];
  courses_exp = [];
  item_select = { link: '', ut_id: '' };
  agree_click(modal) {
    this.api.postData("agree", { ut_id: this.item_select.ut_id }).then((res: any) => {
      if (res == '1') {
        modal.dismiss('Cross click')
        this.router.navigate([this.item_select.link]);
      } else {
        this.api.error("");

      }
    })

  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(this.croppedImage);

  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  item_click(item, agree) {
    console.log(item);
    if (item.data_start_text > 0) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success ml-2',
          cancelButton: 'btn btn-dark ml-2'
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons.fire({
        title: 'ยังไม่เปิดให้อบรม',
        // text: "รอผลการอนุมัติภายใน 3 วันทำการระบบจะแจ้งผลการอนุมัติ และข้อมูลการเข้าระบบไปยัง email ของท่าน",
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'ตกลง',
        //                                                cancelButtonText: 'ปิดออก',
        reverseButtons: true
      }).then(function (result) {

      });
    } else {
      this.item_select = item;

      if (item.agree == '0' && item.is_sliding == '0') {
        // console.log("agree");
        this.modalService.open(agree, { size: 'lg' });
      } else {
        this.router.navigate([item.link]);
      }
    }


  }
  open(agree) {
    this.modalService.open(agree, { size: 'lg' });
  }
  async ngOnInit() {

    let setup = localStorage.getItem("setup");
    if (setup == null) {
      this.router.navigate(['/setup']);
    }
    // const swalWithBootstrapButtons = Swal.mixin({
    //   customClass: {
    //     confirmButton: 'btn btn-success ml-2',
    //     cancelButton: 'btn btn-dark ml-2'
    //   },
    //   buttonsStyling: false
    // });
    // swalWithBootstrapButtons.fire({
    //   title: 'แจ้งปิดปรับปรุงระบบวันที่ 24 สิงหาคม 2556 เวลา 00.30 ใช้เวลาประมาณ 20 นาที ขออภัยในความไม่สะดวก',
    //   // text: "รอผลการอนุมัติภายใน 3 วันทำการระบบจะแจ้งผลการอนุมัติ และข้อมูลการเข้าระบบไปยัง email ของท่าน",
    //   icon: 'info',
    //   showCancelButton: false,
    //   confirmButtonText: 'ตกลง',
    //   //                                                cancelButtonText: 'ปิดออก',
    //   reverseButtons: true
    // }).then(function (result) {

    // });

    $("#loading").show();
    this.load_profile();
    this.api.getData("get_course").then(async (res: any) => {
      $("#loading").hide();
      this.courses = res;
      // console.log(this.courses);
      await this.courses.forEach((ele) => {
        if (ele.topic_selected == '' && ele.is_custom == '1') {
          ele.link = 'choose-topic/' + ele.rt_id + '/' + ele.uri;
        } else {
          ele.link = 'course/' + ele.rt_id + '/' + ele.uri;
        }
      });
      this.courses.forEach(element => {
        if (element.is_exp) {
          this.courses_exp.push(element);
        } else {
          this.courses_not_exp.push(element);
        }
      });
    })
  }
  selectFiles(event) {
    // this.selectedFiles = event.target.files;
    this.imageChangedEvent = event;
    // setTimeout(() => {
    //   this.uploadFiles();
    // }, 100);
  }
  // uploadFiles() {
  //   for (let i = 0; i < this.selectedFiles.length; i++) {
  //     this.upload(this.selectedFiles[i]);
  //   }
  // }
  save_profile(modal) {
    this.compressImage(this.croppedImage, 350, 350).then(compressed => {
      // this.resizedBase64 = compressed;
      this.upload(compressed, modal);
    })



  }
  upload(file, modal) {
    this.api.upload_base64(file).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {


        } else if (event instanceof HttpResponse) {
          console.log(event.body);
          this.url = event.body.url;
          this.api.postData("save_profile", { url: this.url }).then((res: any) => {
            console.log(res);
            this.load_profile();
            this.imageChangedEvent = null;
            this.api.success("");
            modal.dismiss('Cross click');

          });
          this.message = '';
        }
      },
      err => {

        this.message = 'Could not upload the file:' + file.name;
      });
  }
  inhouse = [];
  upload_profile(content) {
    this.profile = this._formBuilder.group({
      prefix: [this.user.prefix, [Validators.required]],
      first_name: [this.user.first_name, [Validators.required]],
      last_name: [this.user.last_name, [Validators.required]],
      user_email: [this.user.user_email, [Validators.required, Validators.email]],
      phone: [this.user.phone, [Validators.required, Validators.email]],
      cid: [this.user.cid, [Validators.required]],
      // in_h_id: [this.user.in_h_id]
    });
    this.change_pass = this._formBuilder.group({
      old_pass: ['', [Validators.required, Validators.minLength(6)]],
      new_pass: ['', [Validators.required, Validators.minLength(6)]],
      confirm_pass: ['', [Validators.required, Validators.minLength(6)]],
    });
    this.api.getData("get_all_inhouse").then((inhouse: any) => {
      this.inhouse = inhouse;
    })


    this.modalService.open(content, { size: 'sm' });

  }
  change_password_func(modal) {
    console.log(this.change_pass.value);
    this.api.postData("change_password", this.change_pass.value).then((res: any) => {
      console.log(res);
      if (res.flag) {
        this.api.success("");
        modal.dismiss();
      } else {
        this.api.error(res.error);
      }
    })
  }
  change_prifile(modal) {

    let data = this.profile;
    this.api.postData("change_profile", this.profile.value).then((res: any) => {
      console.log(res);
      this.load_profile();
      this.api.success("");
      modal.dismiss('');
    })
    // if(this.profile.status=="VALID"){

    // }else{

    // }
    console.log(data);
  }

  public ngAfterViewInit() {
    // if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    //   navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
    //     this.video.nativeElement.src = window.URL.createObjectURL(stream);
    //     this.video.nativeElement.play();
    //   });
    // }
  }

  public capture() {
    var context = this.canvas.nativeElement.getContext("2d").drawImage(this.video.nativeElement, 0, 0, 640, 480);
    this.captures.push(this.canvas.nativeElement.toDataURL("image/png"));
  }
}
