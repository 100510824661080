import { ConnectApiService } from './../connect.api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-courese',
  templateUrl: './all-courese.component.html',
  styleUrls: ['./all-courese.component.scss']
})
export class AllCoureseComponent implements OnInit {

  constructor(public api: ConnectApiService) { }

  _courese: any = [1, 2, 3, 4, 5, 6, 7, 8]
  ngOnInit(): void {
    this.goTop()
    this.loadTrainingRound(this.cSelect, this._page, this._limit)
    this.loadCoursesSelect()
  }

  goTop() {
    window.scrollTo(0, 0);
  }

  search: string = ''
  searchRound() {
    this.api.postData_nologin('../Front_web/loadTrainingRoundAllSearch', { course: 0, page: 1, limit: this._limit, s: this.search }).then((res: any) => {
      if (res.flag == 1) {
        this._TrainingRound = res.round
        this._round = Math.ceil(res.count / this._limit)
        let _pTmp = []
        for (let i = 1; i <= this._round; i++) {
          _pTmp.push(i)
        }
        this.page = _pTmp ? _pTmp : [1]

      }
    })
  }

  _TrainingRound: any = []
  _page: number = 1
  page: any = [1]
  _limit: number = 20
  _round: number = 0
  loadTrainingRound(type, page, limit) {
    this.api.getData_nologin('../Front_web/loadTrainingRoundAll/' + type + '/' + page + '/' + limit).then((res: any) => {
      if (res.flag == 1) {
        this._TrainingRound = res.round
        this._round = Math.ceil(res.count / limit)
        let _pTmp = []
        for (let i = 1; i <= this._round; i++) {
          _pTmp.push(i)
        }
        this.page = _pTmp ? _pTmp : [1]

      } else {
        this._TrainingRound = []
      }
    })
  }

  cSelect: any = 0
  _Courses: any = []
  loadCoursesSelect() {
    this.api.getData_nologin('../Front_web/loadCoursesSelect').then((res: any) => {
      if (res.flag == 1) {
        this._Courses = res.data
      }
    })
  }

  getRoundByCourse() {
    this.loadTrainingRound(this.cSelect, 1, this._limit)
  }

  pickPage(page) {
    this.loadTrainingRound(this.cSelect, page, this._limit)
  }

  swapPage(type) {
    if (type == 'pos') {
      this._page++
      if (this._page > this.page.length) {
        this._page--
      }
    } else {
      this._page--
      if (this._page < 1) {
        this._page = 1
      }
    }
    this.loadTrainingRound(this.cSelect, this._page, this._limit)
  }

}
