<app-header></app-header>
<div class="setup">
    <div class="header">
        <div class="container">
            การตรวจเช็คสถานะอุปกรณ์และความพร้อม
        </div>

    </div>
    <div class="container2">
        <div class="text-center">
            <button class="btn btn-info recheck" (click)="reload()">
                <img src="../../assets/images/Icon material-replay.png" style="width: 30px;">
                ตรวจเช็คใหม่อีกครั้ง</button>
        </div>
        <ul class="list-group">
            <li class="list-group-item d-flex  align-items-center">
                <img src="../../assets/images/Icon awesome-camera.png">
                <span class="title">
                    กล้อง
                </span>
                <div class="float-right">
                    <img *ngIf="data.camera" src="../../assets/images/icons8_checkmark_48px.png">
                    <img *ngIf="!data.camera" src="../../assets/images/icons8_delete_48px.png">

                </div>
            </li>
            <li class="list-group-item d-flex  align-items-center">
                <img src="../../assets/images/Icon material-ondemand-video.png">
                <span class="title">
                    การเล่นวิดีโอ
                </span>
                <div class="float-right">
                    <img *ngIf="data.video" src="../../assets/images/icons8_checkmark_48px.png">
                    <img *ngIf="!data.video" src="../../assets/images/icons8_delete_48px.png">
                </div>
            </li>
            <li class="list-group-item d-flex  align-items-center">
                <img src="../../assets/images/Icon awesome-volume-up.png">
                <span class="title">
                    การเล่นเสียง
                </span>
                <div class="float-right">
                    <img *ngIf="data.sound" src="../../assets/images/icons8_checkmark_48px.png">
                    <img *ngIf="!data.sound" src="../../assets/images/icons8_delete_48px.png">
                </div>
            </li>
            <li class="list-group-item d-flex  align-items-center">
                <img src="../../assets/images/Icon simple-javascript.png">
                <span class="title">
                    การทำงานของ JavaScript
                </span>
                <div class="float-right">
                    <img *ngIf="data.javascript" src="../../assets/images/icons8_checkmark_48px.png">
                    <img *ngIf="!data.javascript" src="../../assets/images/icons8_delete_48px.png">
                </div>
            </li>
            <li class="list-group-item d-flex  align-items-center">
                <img src="../../assets/images/Icon metro-file-pdf.png">
                <span class="title">
                    การอ่านไฟล์ PDF
                </span>
                <div class="float-right">

                    <img *ngIf="data.pdf" src="../../assets/images/icons8_checkmark_48px.png">
                    <img *ngIf="!data.pdf" src="../../assets/images/icons8_delete_48px.png">
                </div>
            </li>
            <li class="list-group-item d-flex  align-items-center">
                <img src="../../assets/images/Icon open-browser.png">
                <span class="title">
                    บราวเซอร์ที่ใช้
                </span>
                <div class="float-right">

                    <img *ngIf="data.browser" src="../../assets/images/icons8_checkmark_48px.png">
                    <img *ngIf="!data.browser" src="../../assets/images/icons8_delete_48px.png">
                </div>
            </li>
            <li class="list-group-item d-flex  align-items-center">
                <img src="../../assets/images/Icon awesome-cookie-bite.png">
                <span class="title">
                    การเก็บข้อมูลลง Cookie
                </span>
                <div class="float-right">
                    <img *ngIf="data.cookie" src="../../assets/images/icons8_checkmark_48px.png">
                    <img *ngIf="!data.cookie" src="../../assets/images/icons8_delete_48px.png">
                </div>
            </li>
        </ul>
        <div class="error" *ngIf="have_error()">
            อุปกรณ์ของคุณยังไม่พร้อมสำหรับการเข้าเรียน
        </div>
        <div class="error-list" *ngIf="have_error()">
            <ul>
                <li (click)="view_video(content)">
                    <div class="img">
                        <img src="../../assets/images/itg-192.png">
                    </div>
                    <div class="content">
                        <div>วิธีการตั้งค่าและเปิดการใช้งานกล้องเว็บแคม</div>
                        <label for="">12:20</label>
                    </div>

                </li>
                <li>
                    <div class="img">
                        <img src="../../assets/images/itg-1.png" alt="">
                    </div>
                    <div class="content">
                        <div>How to Set Google Chrome As Default</div>
                        <label for="">12:20</label>
                    </div>
                </li>
                <li>
                    <div class="img">
                        <img src="../../assets/images/itg-194.png" alt="">
                    </div>
                    <div class="content">
                        <div>ล้างแคชเคลียร์ข้อมูลในบราวเซอร์ทำอย่างไร</div>
                        <label for="">12:20</label>
                    </div>
                </li>
            </ul>
        </div>
        <div class="summery" *ngIf="have_error()">
            <span>
                อุปกรณ์ของคุณยังไม่พร้อมสำหรับการเข้าเรียน
            </span>
            <button class="btn btn-danger" style="float: right;" (click)="logout()">ออกจากระบบ</button>
        </div>
        <div class="summery" *ngIf="!have_error()">
            <span >
                อุปกรณ์ของคุณยังพร้อมสำหรับการเข้าเรียน
            </span>
            <a routerLink="/home">
                <img src="../../assets/images/btn_to_home.png" alt="">
            </a>
        </div>
    </div>
</div>
<webcam (initError)="handleInitError($event)" style="display: none;"></webcam>
<ng-template #content let-modal>
    <div class="modal-body" style="padding:5px 5px 0px 5px;">
        <div class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <img src="../../assets/images/Icon metro-cross.png">
        </div>
        <iframe style="width: 100%;min-height: 300px;" src="https://www.youtube.com/embed/EA7nQbHf2DA"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
    </div>
</ng-template>
<app-footer></app-footer>