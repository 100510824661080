import { ConnectApiService } from './../connect.api.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-home2',
  templateUrl: './home2.component.html',
  styleUrls: ['./home2.component.scss']
})
export class Home2Component implements OnInit {
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  _courese: any = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  _knowledge: any = []

  constructor(public api: ConnectApiService) {
    this.coureseList()
    this.loadBG()
    this.loadTrainingRound()
  }

  ngOnInit(): void {
    setTimeout(() => {
      let el: HTMLElement = this.myDiv.nativeElement;
      el.click();
    }, 2000);
  }

  _bg: any = []
  loadBG() {
    this.api.getData_nologin('../Front_web/loadBG').then((res: any) => {
      console.log(res);
      if (res) {
        this._bg = res
      }
    })
  }

  coureseList() {
    this.api.getData_nologin('../Front_web/getKnowledge').then((res: any) => {
      if (res) {
        this._knowledge = res
      }
    })
  }

  _TrainingRound: any = []
  loadTrainingRound() {
    this.api.getData_nologin('../Front_web/loadTrainingRound/limit').then((res: any) => {
      if (res) {
        this._TrainingRound = res
      }
    })
  }

}
