<app-header2></app-header2>

<div id="body" class="content">
    <div class="header-page">
        <div class="p-4 pl-5">
            <h1 class="container p-30">

            </h1>
        </div>
    </div>
    <div class="container pb-5">
        <!-- OneTrust Cookies Settings button start -->
        <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
        <!-- OneTrust Cookies Settings button end -->

        <!-- OneTrust Cookies List start -->
        <div id="ot-sdk-cookie-policy"></div>
        <!-- OneTrust Cookies List end -->
    </div>
</div>

<app-footer2></app-footer2>