import { ApproveComponent } from './approve/approve.component';
import { RegisterRoundComponent } from './register-round/register-round.component';
import { InhouseCoursesComponent } from './inhouse-courses/inhouse-courses.component';
import { QAComponent } from './qa/qa.component';
import { DetailCoureseComponent } from './detail-courese/detail-courese.component';
import { LearningComponent } from './learning/learning.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CourseComponent } from './course/course.component';
import { HomeComponent } from './home/home.component';
import { SetupComponent } from './setup/setup.component';
import { ComingsoonComponent } from './comingsoon/comingsoon.component';
import { TopicComponent } from './topic/topic.component';
import { KycComponent } from './kyc/kyc.component';
import { ChooseTopicComponent } from './choose-topic/choose-topic.component';
import { ForgotComponent } from './forgot/forgot.component';
import { NewPassComponent } from './new-pass/new-pass.component';
import { Home2Component } from './home2/home2.component';
import { AllCoureseComponent } from './all-courese/all-courese.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ManualComponent } from './manual/manual.component';
import { PreapproveComponent } from './preapprove/preapprove.component';
import { RegisterPreapproveComponent } from './register-preapprove/register-preapprove.component';
import { CookieListComponent } from './cookie-list/cookie-list.component';
import { WebConsentComponent } from './web-consent/web-consent.component';
import { CookiePrivacyComponent } from './cookie-privacy/cookie-privacy.component';
import { ContractDpoComponent } from './contract-dpo/contract-dpo.component';
// setInterval(() => {
//   let d = new Date();
//   console.log(d.getMinutes());
//   if (d.getMinutes() >= 30 && d.getMinutes() <= 50) {
//     window.location.href = "https://e-learning.aimc.or.th/comingsoon";
//   }
// }, 60000);
// let d = new Date();
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  //  { path: 'login', component: ComingsoonComponent },
  { path: 'cookie-list', component: CookieListComponent },
  { path: 'contract-dpo', component: ContractDpoComponent },
  // { path: 'web-consent', component: WebConsentComponent },
  { path: 'cookie-privacy', component: CookiePrivacyComponent },
  { path: 'newpass/:code/:otp', component: NewPassComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'home', component: HomeComponent },
  // { path: 'home', component: HomeComponent },
  { path: 'manual', component: ManualComponent },
  { path: 'all-courese', component: AllCoureseComponent },
  { path: 'detail-courese/:id', component: DetailCoureseComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'faq', component: QAComponent },
  { path: 'inhouse-coureses/:code', component: InhouseCoursesComponent },
  { path: 'approve/:code', component: ApproveComponent },
  { path: 'preapprove/:code', component: PreapproveComponent },
  { path: 'register-round/:code', component: RegisterRoundComponent },
  { path: 'register-preapprove/:code', component: RegisterPreapproveComponent },
  { path: 'home2', component: Home2Component },
  { path: 'topic/:courseid/:topicid/:rt_id', component: TopicComponent },
  { path: 'course/:rt_id/:courseid', component: CourseComponent },
  { path: 'choose-topic/:rt_id/:courseid', component: ChooseTopicComponent },
  { path: 'setup', component: SetupComponent },
  { path: 'learning/:courseid/:topicid/:ref_id/:rt_id', component: LearningComponent },
  { path: 'kyc/:ut_id/:topicid/:uri/:rt_id', component: KycComponent },
  // { path: 'comingsoon', component: ComingsoonComponent },
  { path: '**', component: Home2Component },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const rountingcomponents = [HomeComponent, CourseComponent, LoginComponent]
