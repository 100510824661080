import { ConnectApiService } from './../connect.api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qa',
  templateUrl: './qa.component.html',
  styleUrls: ['./qa.component.scss']
})
export class QAComponent implements OnInit {

  constructor(public api: ConnectApiService) { }

  // _QA: any = [
  //   { val: 1, class: 'fas fa-plus-circle' },
  //   { val: 1, class: 'fas fa-plus-circle' },
  //   { val: 1, class: 'fas fa-plus-circle' },
  //   { val: 1, class: 'fas fa-plus-circle' },
  //   { val: 1, class: 'fas fa-plus-circle' }
  // ]

  ngOnInit(): void {
    this.goTop()
    this.loadFAQ()
  }

  goTop() {
    window.scrollTo(0, 0);
  }

  _FAQ: any = []
  loadFAQ() {
    this.api.getData('../Front_web/loadFAQ').then((res: any) => {
      this._FAQ = res
      this._FAQ.forEach((el,index) => {
        this._FAQ[index].val = 1
        this._FAQ[index].class = 'fas fa-plus-circle'
      });
      console.log(this._FAQ);

    })
  }

  onPick(index, val) {
    console.log(index);

    if (this._FAQ[index].val === 1) {
      this._FAQ[index].val = 0
      this._FAQ[index].class = 'fas fa-minus-circle'
    } else {
      this._FAQ[index].val = 1
      this._FAQ[index].class = 'fas fa-plus-circle'
    }
  }

}
