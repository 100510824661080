<app-header2></app-header2>

<div id="body" class="content">
    <div class="header-page">
        <div class="p-4 pl-5">
            <h1 class="container p-30">

            </h1>
        </div>
    </div>
    <div class="map-wrapper pt-lg--10 pt-5 pb-lg--10 pb-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="contact-wrap bg-white shadow-lg rounded-lg position-relative top-0">
                        <h1 class="text-grey-900 fw-700 display3-size mb-5 lh-1">ติดต่อสอบถามเกี่ยวกับ DPO</h1>
                        <h3 class="fw-700 font-sm mt-4 mb-3 text-grey-900">ชื่อ: คุณนันท์นภัส</h3>
                        <h3 class="fw-700 font-sm mt-4 mb-3 text-grey-900">เบอร์โทร: 0-2264-0900 ต่อ 603</h3>
                        <h3 class="fw-700 font-sm mt-4 mb-3 text-grey-900">E-mail: dpo@aimc.or.th</h3>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<app-footer2></app-footer2>