import { ConnectApiService } from './../connect.api.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inhouse-courses',
  templateUrl: './inhouse-courses.component.html',
  styleUrls: ['./inhouse-courses.component.scss']
})
export class InhouseCoursesComponent implements OnInit {

  _courese: any = [1, 2, 3, 4, 5, 6]
  _statusPage: number = 0
  url: string = ''
  constructor(public api: ConnectApiService, private route: ActivatedRoute) {
    this.url = this.route.snapshot.paramMap.get("code")
  }

  ngOnInit(): void {
    this.loadCourses(this.url)
  }

  _inhouse: any = {}
  loadCourses(code) {
    this.api.getData_nologin('../Front_web/loadCourses/' + code).then((res: any) => {
      this._inhouse = res.inhouse
      this._courese = res.round
      this._statusPage = res.status
    })
  }

  _icon_more: string = 'fas fa-chevron-down'
  moredetail() {
    if (this._icon_more == 'fas fa-chevron-down') {
      this._icon_more = 'fas fa-chevron-up'
    } else {
      this._icon_more = 'fas fa-chevron-down'
    }

  }

}
