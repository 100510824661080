import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectApiService } from '../connect.api.service';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {
  items$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);  // อัปเดต BehaviorSubject เป็นอาร์เรย์

  constructor(private route: ActivatedRoute, public api: ConnectApiService, private router: Router) { }

  courseid = '';
  rt_id = "";
  user: any = {
    first_name: "",
    last_name: "",
    phone: "",
    type: "0",
    user_email: "0",
    user_id: "0",
  };

  topics = { data: [], course: { cg_title: '', rt_title: '', time: '', is_custom: '0', kyc: '', ut_id: '', verify: '1' } };

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("userdata"));
    this.courseid = this.route.snapshot.paramMap.get("courseid");
    this.rt_id = this.route.snapshot.paramMap.get("rt_id");
    this.api.getData("get_topic2/" + this.rt_id + "/" + this.courseid).then((res: any) => {
      if (res.redirect === true) {
        Swal.fire({
          title: 'มีปัญหากับการเลือกหัวข้อ',
          text: 'โปรดเลือกใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonColor: '#FF9920',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          this.router.navigate(['/home']).then(() => {
            window.location.reload();
          });
        });
      } else {
        this.topics = res;
        this.get_finish().forEach((val: any) => {
          if (val.percen - 0 == 100 && val.cer == '') {
            this.create_cer(val);
          }
        });
      }
    });
  }

  download(cer) {
    window.open(this.api.main_url() + 'api/certificate/' + cer, '_blank').focus();
  }

  get_not_finish() {
    return this.topics.data.filter(item => item.percen - 0 !== 100);
  }

  get_finish() {
    return this.topics.data.filter(item => item.percen - 0 == 100);
  }

  goto_url(courseid, code_uri) {
    if (this.topics.course.kyc == '' && this.topics.course.verify == '1') {
      this.router.navigate(['/kyc/' + this.topics.course.ut_id + '/' + this.courseid + '/' + code_uri + "/" + this.rt_id]);
    } else {
      this.router.navigate(['/topic/' + courseid + '/' + code_uri + '/' + this.rt_id]);
    }
  }

  create_cer(item) {
    item.click = true;

    this.api.postData("create_cer", { tg_id: item.tg_id, ut_id: this.topics.course.ut_id }).then((res: any) => {
      if (res.success - 0 == 1) {
        setTimeout(() => {
          this.ngOnInit();
        }, 1000);
      }
      //  console.log('123123123123123',res);
      // if (res.success == 1) {
      //   const updatedItems = this.items$.getValue().map(i =>
      //     i.tg_id === item.tg_id ? { ...i, request_cer: 1 } : i
      //   );
      //   this.items$.next(updatedItems);  // อัปเดต item ที่เฉพาะเจาะจงใน BehaviorSubject
      // }
    }).catch((error) => {
      console.error("Error creating certificate:", error);
      item.click = false;
    });
  }
}
