import { ConnectApiService } from './../connect.api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(public api: ConnectApiService) { }

  ngOnInit(): void {
    this.goTop()
    this.loadAbout()
  }

  _anout: any = []
  loadAbout() {
    this.api.getData_nologin('../Front_web/loadAbout').then((res: any) => {
      if (res) {
        this._anout = res
      }
    })
  }

  goTop() {
    window.scrollTo(0, 0);
  }

}
