import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectApiService } from '../connect.api.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-new-pass',
  templateUrl: './new-pass.component.html',
  styleUrls: ['./new-pass.component.scss']
})
export class NewPassComponent implements OnInit {
  Login: FormGroup;
  constructor(private _formBuilder: FormBuilder, public api: ConnectApiService, private router: Router, private route: ActivatedRoute) { }
  otp = "";
  code = "";
  ngOnInit(): void {

    this.code = this.route.snapshot.paramMap.get('code');
    this.otp = this.route.snapshot.paramMap.get('otp');


    this.Login = this._formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmpass: ['', [Validators.required]]
    });
  }
  confirm() {
    console.log(this.Login.value);
    this.api.postData("new_password", { pass: this.Login.value.password, code: this.code, otp: this.otp }).then((res: any) => {
      console.log(res);
      if (res == '1') {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
          title: 'เรียบร้อย',
          text: "แก้ไขรหัสผ่านของคุณเรียบร้อยแล้ว",
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'ล็อกอิน',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true
        }).then((result) => {
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 500);
        })
      } else {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
        swalWithBootstrapButtons.fire(
          'Error',
          'มีปัญหาในการเปลี่ยนรหัสผ่าน กรุณาขอรหัสผ่านใหม่',
          'error'
        );
        setTimeout(() => {
          this.router.navigate(['/forgot']);
        }, 3000);

      }
    });
  }
}
