import { Component, Input, OnInit } from '@angular/core';
import { ConnectApiService } from '../connect.api.service';

@Component({
  selector: 'app-headerapprove',
  templateUrl: './headerapprove.component.html',
  styleUrls: ['./headerapprove.component.scss']
})
export class HeaderapproveComponent implements OnInit {
  @Input() url = '';
  _passcodeState = 0
  constructor(public api: ConnectApiService) { }

  ngOnInit(): void {
    setTimeout(() => {
      let check: any = JSON.parse(this.api.storage_get('_passcodeState')) ? JSON.parse(this.api.storage_get('_passcodeState')) : { code: null }
      if (check.code == this.url) {
        if (check.state == 1) {
          this._passcodeState = 1
        } else {
          this._passcodeState = 2
        }
      } else {
        this._passcodeState = 2
      }
    }, 300);
  }
  logout() {
    this.api.storage_set('_passcodeState', null);
    this.api.getData_nologin("../Service/approve_exit").then((res: any) => {
      location.href = 'https://e-learning.aimc.or.th';
    })
  }

}
