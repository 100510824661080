import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ConnectApiService } from './../connect.api.service';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ComponentFactoryResolver } from '@angular/core';
import Swal from 'sweetalert2'
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-preapprove',
  templateUrl: './preapprove.component.html',
  styleUrls: ['./preapprove.component.scss']
})
export class PreapproveComponent implements OnInit {
  @ViewChild('_ischeck') checkbox: ElementRef<HTMLInputElement>;
  a: number = 0
  b: number = 0
  page: any = '1'
  url: string = ''
  data = {
    user: [],
    data: {}
  };
  message = '';
  import_data = [];
  selectedFiles: FileList;
  progressInfos = { value: 0, fileName: '' };;
  @ViewChild('import_modal') importmodalEle;
  constructor( private router:Router , public api: ConnectApiService, private route: ActivatedRoute, public ref: ChangeDetectorRef, private modalService: NgbModal, public ModalConfig: NgbModalConfig) {
    this.url = this.route.snapshot.paramMap.get("code");
  }

  ngOnInit(): void {
    // window.addEventListener('storage', (event : any) => {
    //   if (
    //     event.key === 'approve_token'  || event.key === 'backend_token' ||  event.key === 'access_token'
    //   ) {
    //     localStorage.clear();
    //     this.router.navigate(['']);
    
    //   } 
    // });

    
    this.checkPass()
    setTimeout(() => {
      if (this._passcodeState == 1) {
        this.load(this.url, this.page)
        this.round(this.url)
      }
    }, 500);
  }

  checkPass() {
    setTimeout(() => {
      let check: any = JSON.parse(this.api.storage_get('_passcodeState')) ? JSON.parse(this.api.storage_get('_passcodeState')) : { code: null }
      if (check.code == this.url) {
        if (check.state == 1) {
          this._passcodeState = 1
        } else {
          this._passcodeState = 2
        }
      } else {
        this._passcodeState = 2
      }
    }, 300);
  }

  passcode = ''
  _passcodeState = 0
  _passcodeStateStr = ''
  confirmPass(pass) {
    this.api.postData_nologin("../Service/checkPassCode_post", { url: this.url, pass: pass }).then((res: any) => {
      // console.log(res);
      if (res.flag == 1) {
        this.api.storage_set_string('approve_token', res.token)
        this.api.storage_set('_passcodeState', { code: this.url, state: 1 })
        this._passcodeState = 3
        this._passcodeStateStr = ''
        setTimeout(() => {
          location.reload()
        }, 300);
      } else {
        this._passcodeState = 2
        this._passcodeStateStr = 'รหัสผ่านไม่ถูกต้อง!'
      }
    })
    // this.api.getData_nologin('../Service/checkPassCode/' + this.url + '/' + pass).then((res: any) => {
    //   if (res.flag == 1) {
    //     this.api.storage_set('approve_token', res.token)
    //     this.api.storage_set('_passcodeState', { code: this.url, state: 1 })
    //     this._passcodeState = 3
    //     this._passcodeStateStr = ''
    //     setTimeout(() => {
    //       location.reload()
    //     }, 300);
    //   } else {
    //     this._passcodeState = 2
    //     this._passcodeStateStr = 'รหัสผ่านไม่ถูกต้อง!'
    //   }
    // })
  }

  max_user: any = 0
  user_approved: any = 0
  data_page: any = [];
  download() {
    window.location.href = this.api.main_url() + "api/template/template.xlsx";
  }
  load(code, page) {
    this.api.getData_approve('../Service/load_json/' + code + '/' + page).then((res: any) => {
      this.data = res;
      this.max_user = res.data.max_user
      this.user_approved = res.data.user_approved
      let count_user = res.data.count_user
      this.data_page = []
      if (count_user > 0) {
        for (let i = 1; i <= count_user; i++) {
          this.data_page.push(i.toString())
        }
      } else {
        this.data_page = ['0']
      }

      this.data.user.forEach((el, index) => {
        el._ischeck = false
      });
    })
  }

  course: any = { cg_title: '' }
  round(code) {
    this.api.getData_approve("../Service/loadRound/" + code).then((res) => {
      this.course = res
    })
  }


  check: any = []
  remove_user() {
    this.check = []
    this.data.user.forEach((el, index) => {
      if (el._ischeck) {
        this.check.push(el);
      }
    })
    if (this.check.length > 0) {
      Swal.fire({
        icon: 'question',
        title: 'ยืนยันการลบ ' + this.user_select + ' คน',
        text: 'การลบจะไม่สามารถนำกลับคืนมาได้',
        showCancelButton: true,
        confirmButtonText: `Confirm`,
      }).then((result) => {
        if (result.isConfirmed) {

          this.api.postData_approve("remove_user_temp", this.check).then((res) => {
            if (res == '1') {
              this.load(this.url, 1);
              this.user_select = 0
              this.check_all = false

              Swal.fire('ลบออกเรียบร้อยแล้ว', '', 'success');

            } else {
              Swal.fire('มีปัญหาในการลบ', '', 'error');
            }


          })
        }
      })
    } else {
      Swal.fire('กรุณาเลือกข้อมูลที่จะลบ', '', 'warning');
    }

  }

  changePage(page) {
    this.load(this.url, page)
    if (page == 0) {
      this.page = 1
    }
  }

  onCopy(val) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'คัดลอกแล้ว',
      showConfirmButton: false,
      timer: 1500
    })
  }

  export(code) {
    this.api.getData_approve("../Service/export_user_register/" + code).then((res: any) => {
      if (res) {
        location.href = res.link_excel
      }
    })
  }
  remove(index) {
    if (index > -1) {
      this.import_data.splice(index, 1); // 2nd parameter means remove one item only
    }
  }
  close() {
    this.importmodalEle.close('Close click');
  }
  upload(idx, file) {
    this.progressInfos = { value: 0, fileName: file.name };
    this.api.upload_user(file).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos.value = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          console.log(event);
          this.import_data = event.body;

          console.log(this.import_data);

        }
      },
      err => {
        this.progressInfos[idx].value = 0;
        this.message = 'Could not upload the file:' + file.name;
      });
  }
  uploadFiles() {
    this.message = '';
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.upload(i, this.selectedFiles[i]);
    }
  }
  selectFiles(event) {
    this.progressInfos = { value: 0, fileName: '' };;
    this.selectedFiles = event.target.files;
    setTimeout(() => {
      this.uploadFiles();
    }, 100);
  }
  import(code) {
    console.log(code);
    let dialogRef = this.modalService.open(this.importmodalEle, { size: 'lg' });
    // this.api.getData_nologin("../Service/import_user_register/" + code).then((res: any) => {
    //   if (res) {
    //     // location.href = res.link_excel
    //   }
    // })
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`); // Pizza!
    // });

  }
  import_data_f() {
    console.log("import_data");
    this.api.postData_approve("import_pre", { data: this.import_data, code: this.url }).then((res) => {
      console.log(res);
      this.load(this.url, 1)
      this.import_data = [];
      this.progressInfos.value = 0;
      Swal.fire('นำเข้าเรียบร้อยแล้ว', '', 'success');
    })
  }
  exit() {
    Swal.fire({
      icon: 'question',
      title: 'ต้องการออกจากระบบหรือไม่?',
      showCancelButton: true,
      confirmButtonText: 'ออกจากระบบ',
      confirmButtonColor: '#dc3545',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.getData_nologin("../Service/approve_exit").then((res: any) => {
          location.href = this.api.main_url() + 'api/service/approve/' + this.url
        })
      }
    })
  }

  check_all = false
  onCheckAll() {
    this.user_select = 0
    this.data.user.forEach((el) => {
      if (this.check_all) {
        el._ischeck = true
      } else {
        el._ischeck = false
      }

      if (this.check_all && el.status == 0) {
        this.user_select++
      }
    });
  }

  check_model = false
  user_select = 0
  onCheck(val, index) {
    if (!this.data.user[index]._ischeck) {
      this.data.user[index]._ischeck = true
      this.user_select++
    } else {
      this.data.user[index]._ischeck = false
      this.user_select--
    }
  }

  search: any = ''
  findUser(s) {
    let data_s = {
      s: s,
      code: this.url,
      page: 1
    }
    this.api.postData_approve('../Service/findUser', data_s).then((res: any) => {
      if (res.flag == 1) {
        this.data = res;
        this.data.user.forEach((el, index) => {
          el._ischeck = false
        });
      } else {
        this.load(this.url, 1)
      }
      this.ref.detectChanges()
    })
  }

}
