<app-header2></app-header2>

<div class="content-regisform container my-3">
    <div class="row mt-1">
        <div class="col-12" style="display: flex;justify-content:
            space-around;">
            <div class="card rounded shadow-sm m-1" style="max-width: 732px;">
                <div class="px-3 pt-4">
                    <div class="border-bottom" style="border-color:
                        #386991!important;">
                        <label class="font-weight-bold h5 text-center"
                            style="color: #386991;">ลงทะเบียนอบรม
                            AIMC</label>
                        <br>
                        <!-- <div class="float-right badge badge-danger" *ngIf="course.total>=course.max_user">เต็มแล้ว</div> -->
                        <label class="font-weight-bold" style="color: #386991;">
                            หลักสูตร : {{course.cg_title}}
                            <span class="font-weight-light"></span>
                        </label>
                        <label class="font-weight-bold" style="color: #386991;">
                            รอบ : {{course.title}}
                            <span class="font-weight-light"></span>
                        </label>
                        <!-- <label class="font-weight-bold" style="color: #386991;">
                            จำนวนผู้ลงทะเบียนเอง (
                            <span> {{course.total}} </span>
                            / {{course.max_user}} ) คน
                        </label> -->
                    </div>
                    <div class="my-3">
                        <form [formGroup]="formGroup">
                            <div class="row">
                                <div class="col-md-6 py-2">
                                    <label class="font-weight-bold"
                                        style="color: #386991;">รหัสพนักงาน /
                                        รหัสตัวแทน
                                        <span style="color: red;">*</span>
                                        <!-- <i class="far fa-address-card"></i> -->
                                    </label>
                                    <div class="input-group mb-3">
                                        <input class="form-control"
                                            (keyup.enter)="change_user_ref(formGroup.get('user_ref').value)"
                                            formControlName="user_ref"
                                            type="text" required>
                                        <div class="input-group-append">
                                            <button class="btn btn-success"
                                                (click)="change_user_ref(formGroup.get('user_ref').value)"
                                                type="button">ค้นหา</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 py-2">
                                    <label class="font-weight-bold"
                                        style="color: #386991;">รหัสบัตรประชาชน
                                        <span
                                            style="color: red;">*</span>
                                        <!-- {{json(formGroup.get("cid"))}} -->
                                        <!-- <i class="far fa-id-card"></i> -->
                                    </label>
                                    <input class="form-control"
                                        formControlName="cid" type="text"
                                        required>
                                    <div>
                                        <span class="text-danger"
                                            *ngIf="(formGroup.get('cid').touched ) &&(formGroup.get('cid').errors?.required||formGroup.get('cid').value.length!=13)">
                                            กรุณากรอกตัวเลข 13 หลัก
                                        </span>
                                        <!-- {{formGroup.get("cid") |json}} -->
                                    </div>
                                </div>
                                <div class="col-md-4 py-2">
                                    <label class="font-weight-bold"
                                        style="color: #386991;">คำนำหน้า <span
                                            style="color: red;">*</span>
                                        <!-- <i class="far fa-user-circle"></i> -->
                                    </label>
                                    <select name
                                        (change)="title_change(formGroup.get('name_title').value)"
                                        id
                                        class="form-control"
                                        [hidden]="!hidden_text_title_name"
                                        formControlName="name_title" required>
                                        <option value="นาย">นาย</option>
                                        <option value="นางสาว">นางสาว</option>
                                        <option value="นาง">นาง</option>
                                        <option value>อื่นๆ</option>
                                    </select>
                                    <input class="form-control name_title"
                                        [hidden]="hidden_text_title_name"
                                        formControlName="name_title" type="text"
                                        required>
                                </div>
                                <div class="col-md-4 py-2">
                                    <label class="font-weight-bold"
                                        style="color: #386991;">ชื่อ <span
                                            style="color: red;">*</span>
                                        <!-- <i class="fas fa-pen"></i> -->
                                    </label>
                                    <input class="form-control"
                                        formControlName="first_name" type="text"
                                        required>
                                </div>
                                <div class="col-md-4 py-2">
                                    <label class="font-weight-bold"
                                        style="color: #386991;">นามสกุล <span
                                            style="color: red;">*</span>
                                        <!-- <i class="fas fa-pen"></i> -->
                                    </label>
                                    <input class="form-control"
                                        formControlName="last_name" type="text"
                                        required>
                                </div>
                                <div class="col-md-6 py-2">
                                    <label class="font-weight-bold"
                                        style="color: #386991;">เบอร์โทร <span
                                            style="color: red;">*</span>
                                        <!-- <i class="fas fa-phone"></i> -->
                                    </label>
                                    <input class="form-control" required="true"
                                        formControlName="number_phone"
                                        type="tel" required>
                                    <span class="text-danger"
                                        *ngIf="(formGroup.get('number_phone').touched
                                        ) &&(
                                        formGroup.get('number_phone').invalid)">
                                        กรุณากรอกเบอร์โทรให้ถูกต้อง
                                    </span>
                                </div>
                                <div class="col-md-6 py-2">
                                    <label class="font-weight-bold"
                                        style="color: #386991;">Email <span
                                            style="color: red;">*</span>
                                        <!-- <i class="fas fa-mail-bulk"></i> -->
                                    </label>
                                    <input class="form-control" required="true"
                                        formControlName="user_email"
                                        type="email" name="email" required>
                                    <span class="text-danger"
                                        *ngIf="(formGroup.get('user_email').touched
                                        ) &&(
                                        formGroup.get('user_email').errors?.required||formGroup.get('user_email').errors?.email)">
                                        กรุณากรอก Email ให้ถูกต้อง
                                    </span>
                                </div>
                                <div class="col-sm-12"
                                    *ngIf="course.inhouse=='10'">
                                    <hr>
                                    <div>
                                        <strong>กรุณาเลือกวิธีการต่ออายุ</strong>
                                        <br />
                                        1. มีความประสงค์ต่ออายุด้วยตนเอง
                                        (ดำเนินการต่ออายุได้ตั้งแต่ 1 ก.ย. 67 -
                                        15 ธ.ค. 67 : <a
                                            href="https://www.sec.or.th"
                                            target="_blank">www.sec.or.th</a>)

                                        <br /><br />
                                        2. มีความประสงค์ให้ AIMC ต่ออายุให้
                                        (มีค่าบริการเพิ่ม 200 บาท
                                        เจ้าหน้าที่จะติดต่อกลับหลังจากท่านอบรมครบ
                                        15 ชั่วโมง หรือสอบถามที่ ID Line :
                                        @aimcrenew)<br />
                                        <br />
                                        <div *ngIf="show_c3">3.
                                            มีความประสงค์ให้บริษัท เอไอเอ
                                            ต่ออายุให้ (หากต้องการเลือกบริการนี้
                                            ท่านต้องลงทะเบียนสมัครอบรมภายใน 15
                                            ส.ค. 67 และอบรมให้ครบ 15 ช.ม. ภายใน
                                            30 วันนับจากวันที่สมัครเท่านั้น
                                            โดยไม่ต้องกรอกแบบฟอร์มใดๆ)<br />
                                        </div>
                                        <br />
                                        <select class="form-control mt-sm"
                                            style="margin-top: 10px;
                                            margin-bottom: 10px;"
                                            formControlName="renew">
                                            <option [value]="0">กรุณาเลือก...
                                            </option>
                                            <option [value]="1">1.
                                                มีความประสงค์ต่ออายุด้วยตนเอง
                                            </option>
                                            <option [value]="2">2.
                                                มีความประสงค์ให้ AIMC
                                                ต่ออายุให้</option>
                                            <!-- <option [value]="3"
                                                *ngIf="show_c3">3.
                                                มีความประสงค์ให้บริษัท เอไอเอ
                                                ต่ออายุให้</option> -->
                                        </select>
                                    </div>

                                </div>

                            </div>
                            <div class="py-2" *ngIf="course.inhouse=='10'">
                                <button class="btn btn-info btn-block"
                                    (click)="save()"
                                    [disabled]="formGroup.invalid||disable_btn||formGroup.get('renew').value=='0'">ลงทะเบียน</button>
                            </div>
                            <div class="py-2"
                                *ngIf="course.inhouse!='10'">
                                <button class="btn btn-info btn-block"
                                    (click)="save()"
                                    [disabled]="formGroup.invalid||disable_btn">ลงทะเบียน</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer2></app-footer2>