import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectApiService } from '../connect.api.service';
import Swal from 'sweetalert2'
import { Location } from '@angular/common'
@Component({
  selector: 'app-choose-topic',
  templateUrl: './choose-topic.component.html',
  styleUrls: ['./choose-topic.component.scss']
})
export class ChooseTopicComponent implements OnInit {

  constructor(private route: ActivatedRoute, public router: Router, public api: ConnectApiService, private ref: ChangeDetectorRef, private location: Location) { }
  courseid = '';
  rt_id = "";
  topics = { data: [], course: { min_by_side: [], cg_title: '', rt_title: '', time: '', is_custom: '0', duration: 0, ut_id: 0 }, subside: [] };
  tg_ids = [];
  ngOnInit(): void {
    this.courseid = this.route.snapshot.paramMap.get("courseid");
    this.rt_id = this.route.snapshot.paramMap.get("rt_id");

    this.api.getData("get_topic/" + this.rt_id + "/" + this.courseid).then((res: any) => {
      console.log(res);
      this.topics = res;
    })
  }
  get_topic_not_select(s_id, subs_id) {
    let out = [];
    this.topics.data.forEach((element: any) => {
      if (subs_id == 0) {
        if (!element.force_topic && this.tg_ids.indexOf(element.tg_id) == -1 && s_id == element.s_id) {
          out.push(element);
        }
      } else {
        if (!element.force_topic && this.tg_ids.indexOf(element.tg_id) == -1 && s_id == element.s_id && element.subs_id == subs_id) {
          out.push(element);
        }
      }
    });
    return out;
  }
  remove(i) {
    this.tg_ids.splice(i, 1);
  }
  show_time_text(t) {
    if (t < 60) {
      return t + " นาที";
    } else {
      let out = Math.floor(t / 60) + " ชม. ";
      if (t % 60 != 0) {
        out += t % 60 + " นาที";
      }
      return out;
    }
  }
  get_time_force(s_id) {
    let time = 0;
    this.get_topic_force(s_id).forEach((ele) => {
      time += ele.duration - 0;
    })
    return time;
  }
  get_time_all() {
    let sum = 0;
    this.topics.course.min_by_side.forEach(side => {
      sum += side.sum_min;
    });
    return this.show_time_text(sum);
  }
  sum_time_all() {
    let sum = 0;
    this.topics.course.min_by_side.forEach(side => {
      sum += side.sum_min;
    });
    return sum;
  }
  get_time_select(s_id) {
    let time = 0;
    this.get_topic_selected(s_id).forEach((ele) => {
      time += ele.duration - 0;
    })
    return time;
  }
  back(): void {
    this.location.back();
  }
  select(item, side) {
    console.log(item);
    if (item.duration - 0 + this.get_time_force(item.s_id) + this.get_time_select(item.s_id) <= side.sum_min) {
      this.tg_ids.push(item.tg_id);
    } else {
      this.api.log("คุณเลือกได้ไม่เกิน " + this.show_time_text(side.sum_min) + " ในด้านนี้");
    }
  }

  async save_topic() {
    let flag = true;
    await this.topics.course.min_by_side.forEach(side => {
      if (this.get_time_force(side.s_id) + this.get_time_select(side.s_id) != side.sum_min) {
        flag = false;
      }
    });
    if (flag) {
      Swal.fire({
        title: 'ยืนยันการบันทึกรายการหัวข้อที่เลือก',
        html: '<div style="color:#BC0C06;">เมื่อบันทึกรายการแล้วไม่สามารถเปลี่ยนแปลงรายการหัวข้อได้</div>',
        // showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `บันทึกรายการ`,
        confirmButtonColor: '#FF9920',
        cancelButtonText: 'ปิดออก'
        // denyButtonText: `Don't save`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.api.postData('save_topic2', { tg_ids: this.tg_ids, ut_id: this.topics.course.ut_id }).then((res: any) => {
            console.log(res);
            if (res == '1') {
              Swal.fire({
                title: 'บันทึกรายการเรียบร้อย',
                icon: 'success',
                // showCancelButton: true,
                confirmButtonColor: '#FF9920',
                confirmButtonText: 'ไปหน้ารวมหัวข้อที่เลือก'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.router.navigate(['/course/' + this.rt_id + "/" + this.courseid]);
                }
              })

            } else {
              Swal.fire({
                title: 'ขออภัยมีปัญหาในการบันทึก',
                text: 'กรุณาลองใหม่อีกครั้งจนกว่าจะสำเร็จ',
                icon: 'error',
                confirmButtonColor: '#FF9920',
                confirmButtonText: 'กลับไปหน้าแรก'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.router.navigate(['/home']);
                }
              });
            }
          })

        } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
        }
      })


    } else {
      this.api.log("กรุณาเลือกวิชาเรียนให้ครบในแต่ละด้าน");
    }
  }
  get_topic_force(s_id) {
    let out = [];
    this.topics.data.forEach((element: any) => {
      if (element.force_topic && element.s_id == s_id) {
        out.push(element);
      }
    });
    return out;
  }
  get_topic_selected(s_id) {
    let out = [];
    this.topics.data.forEach((element: any) => {
      if (!element.force_topic && element.s_id == s_id) {
        this.tg_ids.forEach(tg_id => {
          if (tg_id == element.tg_id) {
            out.push(element);
          }
        });

      }
    });
    return out;
  }

}
