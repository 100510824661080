import { ConnectApiService } from './../connect.api.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-footer2',
  templateUrl: './footer2.component.html',
  styleUrls: ['./footer2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Footer2Component implements OnInit {

  constructor(public api : ConnectApiService) { }

  ngOnInit(): void {
    this.goTop()
    this.loadFooter()
  }

  goTop(){
    window.scrollTo(0, 0);
  }

  _footer : any = []
  loadFooter(){
    this.api.getData_nologin('../Front_web/loadFooter').then((res:any)=>{
      if(res){
        this._footer = res
      }
    })
  }

}
