<app-header></app-header>
<div class="full">
    <div class="header">
        <div class="container">
            <div class="time">
                <div class="round">รอบ {{topics.course.rt_title}}</div>
                <div class="time2">เวลา {{get_time_all()}}</div>
            </div>
            <div class="badge badge-waning" (click)="back()">
                < กลับ </div>
            <h3> {{topics.course.cg_title}}</h3>

        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-sm-12 ">
                <div class="course">
                    <button class="save btn btn-success btn-sm"
                        (click)="save_topic()">
                        <img src="../../assets/images/Icon awesome-save.png"
                            style="width: 15px; margin-right: 3px;margin-left: 5px;"
                            alt> บันทึกรายการที่เลือก
                    </button>
                    <div class="header_span">
                        เลือกหัวข้อเพิ่มเติมที่ต้องการในตารางด้านซ้ายมือให้ครบจำนวนชั่วโมงที่กำหนด
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 80px;">
            <div class="col-md-12">
                <div class="card" style="margin-bottom: 20px;"
                    *ngFor="let side of topics.course.min_by_side">
                    <div class="card-header font-bold "
                        style="font-weight: bold;"
                        [ngClass]="{'bg-success text-white': get_time_force(side.s_id)+get_time_select(side.s_id)==side.sum_min}">
                        ด้าน : {{side.title}}
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card" *ngIf="side.s_id!=5">
                                    <h5
                                        class="card-header">รายชื่อหัวข้อทั้งหมดในด้านนี้</h5>
                                    <div class="list-group">
                                        <li
                                            *ngFor="let item of get_topic_not_select(side.s_id,0)"
                                            class="list-group-item list-group-item-action flex-column align-items-start">
                                            <!-- <div>ICR-001</div> -->
                                            <div
                                                class="d-flex w-100 justify-content-between">
                                                <strong
                                                    class>{{item.title}}</strong>
                                                <button
                                                    class="btn btn-info btn-sm"
                                                    (click)="select(item,side)"
                                                    style="width: 60px;">เลือก</button>
                                            </div>
                                            <small
                                                class="time">{{item.time}}</small>
                                            <small>{{item.side}}</small>
                                        </li>
                                    </div>
                                </div>
                                <div *ngIf="side.s_id==5">
                                    <div class="card" style="margin-bottom: 10px;"
                                        *ngFor="let subs of topics.subside">
                                        <h5
                                            class="card-header">{{subs.title}}</h5>
                                        <div class="list-group">
                                            <li
                                                *ngFor="let item of get_topic_not_select(side.s_id,subs.subs_id)"
                                                class="list-group-item list-group-item-action flex-column align-items-start">
                                                <!-- <div>ICR-001</div> -->
                                                <div
                                                    class="d-flex w-100 justify-content-between">
                                                    <strong
                                                        class>{{item.title}}</strong>
                                                    <button
                                                        class="btn btn-info btn-sm"
                                                        (click)="select(item,side)"
                                                        style="width: 60px;">เลือก</button>
                                                </div>
                                                <small
                                                    class="time">{{item.time}}</small>
                                                <small>{{subs.title}}</small>
                                            </li>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6">
                                <div class="card">
                                    <h5 class="card-header">
                                        <span class="pull-right"
                                            style="font-size: 18px;color: #115CA8;">
                                            {{show_time_text(get_time_force(side.s_id)+get_time_select(side.s_id))}}
                                            /
                                            {{show_time_text(side.sum_min)}}
                                        </span>
                                        หัวข้อที่เลือก
                                    </h5>
                                    <div class="list-group">
                                        <div class="heder2"
                                            *ngIf="get_time_force(side.s_id)!=0">
                                            <!-- *ngIf="get_topic_force().length>0" -->
                                            <div
                                                class="pull-right">{{show_time_text(get_time_force(side.s_id))}}
                                            </div>
                                            หัวข้อบังคับ
                                        </div>
                                        <li
                                            *ngFor="let item of get_topic_force(side.s_id)"
                                            class="list-group-item list-group-item-action flex-column align-items-start">
                                            <div>ICR-001</div>
                                            <div
                                                class="d-flex w-100 justify-content-between">
                                                <strong
                                                    class>{{item.title}}</strong>

                                            </div>
                                            <small
                                                class="time">{{item.time}}</small>
                                            <small>{{item.side}}</small>
                                        </li>
                                        <div class="heder3"
                                            *ngIf="side.sum_min-get_time_force(side.s_id)!=0">
                                            <div
                                                class="pull-right">{{show_time_text(get_time_select(side.s_id))}}
                                                /
                                                {{show_time_text(side.sum_min-get_time_force(side.s_id))}}
                                            </div>
                                            หัวข้อเลือกอิสระ
                                        </div>
                                        <li
                                            *ngFor="let item of get_topic_selected(side.s_id);let i = index"
                                            class="list-group-item list-group-item-action flex-column align-items-start">
                                            <div>ICR-001</div>
                                            
                                            <div
                                                class="d-flex w-100 justify-content-between">
                                                <strong
                                                    class>{{item.title}}</strong>
                                                <button
                                                    class="btn btn-danger btn-sm"
                                                    (click)="remove(i)"
                                                    style="width: 50px;">ลบ</button>
                                            </div>
                                            <small
                                                class="time">{{item.time}}</small>
                                            <small>{{item.side}}</small>
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<app-footer></app-footer>