import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectApiService } from '../connect.api.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss']
})
export class TopicComponent implements OnInit {
  constructor(private route: ActivatedRoute, public api: ConnectApiService, public router: Router) { }
  courseid = '';
  topicid = '';
  data = {
    list: [],
    detail: {
      is_exp: false,
      time: '',
      lecturer: { avata: '', name: '', rank: '', bio: '' },
      advice: '',
      topic: '',
      rt_id: '',
      percen: 0,
      document: [],
      tg_id: 0,
      ut_id: 0

    }
  };
  rt_id = '';
  public isCollapsed = false;
  ngOnInit(): void {
    $("#loading").show();
    this.courseid = this.route.snapshot.paramMap.get("courseid");
    this.topicid = this.route.snapshot.paramMap.get('topicid');
    this.rt_id = this.route.snapshot.paramMap.get('rt_id');
    console.log(this.rt_id);
    this.api.getData("get_topic_detail1/" + this.courseid + "/" + this.topicid + "/" + this.rt_id).then((res: any) => {
      console.log(res);
      $("#loading").hide();
      if (res.flag) {
        this.data = res;
        this.data.list.forEach((element, key) => {
          if (key == 0) {
            element.isCollapsed = false;
          } else {
            element.isCollapsed = true;
          }
        });
      } else {

      }


    })

  }
  create_cer() {
    console.log(this.data);
    this.api.postData("create_cer", { tg_id: this.data.detail.tg_id, ut_id: this.data.detail.ut_id }).then((res: any) => {

    })
  }
  start_learning() {
    if (!this.data.detail.is_exp) {
      this.router.navigate(['/learning/' + this.courseid + '/' + this.topicid + '/0/' + this.rt_id]);
    } else {
      this.api.log("รอบอบรมของคุณหมดอายุแล้ว");
    }
  }
  count(type) {
    let count = 0;
    this.data.list.forEach((group, key) => {
      group.lessions.forEach(lession => {
        lession.list.forEach(list => {
          if (list.type == type) {
            count++;
          }
        });
      });
    });
    return count;
  }

  toggle(i) {
    this.data.list[i].isCollapsed = !this.data.list[i].isCollapsed;

  }
  download(doc) {
    // console.log(doc);
    // window.location.href = "https://e-learning.aimc.or.th/api/Media/" + doc.url;
    window.open(this.api.main_url() + "api/Media/" + doc.url, '_blank');
  }

}
