<app-header></app-header>

<div class="top-header">
    <div class="container2">
        <div class="title">

            <div (click)="start_learning()" href class="btn_start">
                เริ่มเรียน <div class="right"><img
                        src="../../assets/images/right.png" width="15"></div>
            </div>
            <a [routerLink]="'/course/'+data.detail.rt_id+'/'+courseid"
                class="btn_start_back">
                < กลับ &nbsp; &nbsp;</a>
            <h2>
                {{data.detail.topic}}
            </h2>
        </div>
        <div class="status">
            <div class="status-text2 float-right">สำเร็จแล้ว
                {{data.detail.percen}}%</div>
            <div class="status-text">สถานะการอบรม</div>
            <div class="process">
                <div class="success" [style]="{'width':data.detail.percen+'%'}"></div>
            </div>
        </div>

        <div class="row">
            <div class="col">{{data.list.length}} <small>sections</small> </div>
            <div class="col">{{count('video')}} <small>Videos</small></div>
            <div class="col">{{count('test')}} <small>Test</small></div>
            <div class="col">{{data.detail.time}}</div>
        </div>
    </div>
</div>
<!-- <button (click)="create_cer()">create cer</button>  -->
<div class="container3">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item ">
            <a class="nav-link active" id="home-tab" data-toggle="tab"
                href="#home" role="tab" aria-controls="home"
                aria-selected="true">คำแนะนำ</a>
        </li>
        <li class="nav-item ">
            <a class="nav-link" id="profile-tab" data-toggle="tab"
                href="#profile" role="tab" aria-controls="profile"
                aria-selected="false">รายละเอียด</a>
        </li>
        <li class="nav-item ">
            <a class="nav-link" id="cod-tab" data-toggle="tab" href="#doc"
                role="tab" aria-controls="doc"
                aria-selected="false">เอกสาร</a>
        </li>
        <li class="nav-item" *ngIf="data.detail.document.length!=0">
            <a class="nav-link" id="contact-tab" data-toggle="tab"
                href="#contact" role="tab" aria-controls="contact"
                aria-selected="false">วิทยากร</a>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade   active show" id="home" role="tabpanel"
            aria-labelledby="home-tab">
            <div [innerHTML]="data.detail.advice">
            </div>

        </div>
        <div class="tab-pane fade course2" id="profile" role="tabpanel"
            aria-labelledby="profile-tab">
            <div class="accordion" id
                *ngFor="let list of data.list;let i = index">
                <div class="card">
                    <div class="card-header" id="headingOne" (click)="toggle(i)"
                        style="cursor: pointer;">
                        <h2 class="mb-0">
                            <button class="btn btn-link" type="button">
                                <i class="icon-action fa fa-chevron-down"
                                    *ngIf="!list.isCollapsed"></i>
                                <i class="icon-action fa fa-chevron-right"
                                    *ngIf="list.isCollapsed"></i>
                                {{list.title}}.
                            </button>
                            <div class="right-head float-right">
                                {{list.time}} <div class="process">
                                    <div class="persen"
                                        [style]="{'width':(list.count.finish/list.count.all*100)+'%'}">
                                    </div>
                                </div>
                                {{list.count.finish+"/"+list.count.all}}
                            </div>
                        </h2>
                    </div>

                    <div *ngFor="let lession of list.lessions"
                        #collapse="ngbCollapse"
                        [(ngbCollapse)]="list.isCollapsed" class="collapse">
                        <div class="card-body">
                            <div class="part">
                                <img
                                    src="../../assets/images/Icon open-document.png">
                                <div class="float-right">
                                    {{lession.time}}
                                </div>
                                <div class="topic-group"> {{lession.title}}

                                </div>
                            </div>
                            <div class="timeline">
                                <div class="row">
                                    <div class="col-md-12 col-lg-12">
                                        <div id="tracking">
                                            <div class="tracking-list">
                                                <div
                                                    *ngFor="let detail of lession.list;let number = index">

                                                    <div class="tracking-item  "
                                                        [ngClass]="{'procress-item': detail.status!=0}">
                                                        <div
                                                            class="tracking-icon status-intransit "
                                                            [ngClass]="{'success': detail.status==2,'procress':detail.procress==1}">
                                                            <i
                                                                class="fas fa-check"
                                                                *ngIf="detail.status==2"></i>
                                                            <span
                                                                *ngIf="detail.status!=2">
                                                                {{number+1}}
                                                            </span>
                                                        </div>
                                                        <div
                                                            *ngIf="detail.type=='video'">
                                                            <div class="img">
                                                                <img
                                                                    src="../../assets/images/Icon awesome-video.png">
                                                            </div>
                                                            <div
                                                                class="tracking-date">
                                                                <img
                                                                    *ngIf="detail.camera==1"
                                                                    src="../../assets/images/Icon ionic-ios-camera-1.png">
                                                                <img
                                                                    *ngIf="detail.camera==2"
                                                                    src="../../assets/images/Icon ionic-ios-camera.png">
                                                                <img
                                                                    *ngIf="detail.quiz==2"
                                                                    class="qu_icon"
                                                                    style="margin-left: 3px;"
                                                                    src="../../assets/images/Icon awesome-question-circle-1.png">
                                                                <img
                                                                    class="qu_icon"
                                                                    *ngIf="detail.quiz==1"
                                                                    src="../../assets/images/Icon awesome-question-circle.png">
                                                            </div>
                                                            <div
                                                                class="tracking-content">
                                                                {{detail.video.title}}
                                                                <span>{{detail.video.time}}</span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            *ngIf="detail.type=='pdf'">
                                                            <div class="img">
                                                                <img
                                                                    src="../../assets/images/Icon metro-file-pdf.png">
                                                            </div>
                                                            <div
                                                                class="tracking-content">
                                                                {{detail.pdf.title}}
                                                                <span>{{detail.pdf.pdf_data.length}}
                                                                    pages /
                                                                    {{detail.pdf.time}}</span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            *ngIf="detail.type=='info'">
                                                            <div class="img">
                                                                <img
                                                                    src="../../assets/images/Icon ionic-md-images.png">
                                                            </div>
                                                            <div
                                                                class="tracking-content">
                                                                {{detail.info.title}}
                                                                <span>{{detail.info.info_data.length}}
                                                                    images /
                                                                    {{detail.info.time}}</span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            *ngIf="detail.type=='canva'">
                                                            <div class="img">
                                                                <img
                                                                    src="../../assets/images/Icon ionic-md-images.png">
                                                            </div>
                                                            <div
                                                                class="tracking-content">
                                                                {{detail.canva.title}}
                                                                <span>
                                                                    {{detail.canva.time}}</span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            *ngIf="detail.type=='test'">
                                                            <div class="img">
                                                                <img
                                                                    src="../../assets/images/Icon metro-question.png">
                                                            </div>
                                                            <div
                                                                class="tracking-content">
                                                                {{detail.exam.title}}
                                                                <span>{{detail.exam.count}}
                                                                    questions /
                                                                    {{detail.exam.time}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            *ngIf="detail.type=='quiz'">
                                                            <div class="img">
                                                                <img
                                                                    src="../../assets/images/quiz-icon-2.png">
                                                            </div>

                                                            <div
                                                                class="tracking-content">
                                                                {{detail.quiz.title}}
                                                                <span>{{detail.quiz.count}}
                                                                    quiz /
                                                                    {{detail.quiz.time}}
                                                                </span>
                                                            </div>

                                                        </div>
                                                        <!-- <div *ngIf="detail.type=='take_picture'">
                                                            <div class="img">
                                                                <img
                                                                    src="../../assets/images/Icon awesome-video.png">
                                                            </div>
                                                            <div class="tracking-content">ถ่ายรูป
                                                               <span>5 questions / 10 min</span> 
                                                            </div>
                                                        </div> -->

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <div class="tab-pane fade" id="doc" role="tabpanel"
            aria-labelledby="doc-tab"
            *ngIf="data.detail.document.length!=0">
            <div class="tutor">
                <div class="doc">
                    <div *ngFor="let doc of data.detail.document"
                        style="cursor: pointer; margin-bottom: 10px;"
                        (click)="download(doc)">
                        <img src="../../assets/images/pdf.png">
                        <span>
                            {{doc.name}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="contact" role="tabpanel"
            aria-labelledby="contact-tab">
            <div class="tutor">
                <div class="avatar">
                    <img src="../../assets/images/profile-2.png"
                        [src]="api.main_url()+ 'api/'+data.detail.lecturer.avata">
                </div>
                <div class="content">
                    <h2>
                        {{data.detail.lecturer.name}}
                    </h2>
                    <span>{{data.detail.lecturer.rank}}</span>
                    <div [innerHTML]="data.detail.lecturer.bio">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>