import { ActivatedRoute } from '@angular/router';
import { ConnectApiService } from './../connect.api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-register-round',
  templateUrl: './register-round.component.html',
  styleUrls: ['./register-round.component.scss']
})
export class RegisterRoundComponent implements OnInit {

  formGroup: FormGroup
  url: string = ''
  constructor(public api: ConnectApiService, private route: ActivatedRoute) {
    this.url = this.route.snapshot.paramMap.get("code")
    this.formGroup = new FormGroup({
      user_ref: new FormControl('', Validators.required),
      name_title: new FormControl('นาย', Validators.required),
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      number_phone: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]),
      user_email: new FormControl('', Validators.email),
      cid: new FormControl('', [Validators.required, Validators.maxLength(13), Validators.minLength(13), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
    })
  }
  json(val) {
    return JSON.stringify(val);
  }
  ngOnInit(): void {
    this.round(this.url)
  }

  course: any = {}
  round(code) {
    this.api.getData_nologin("../Front_web/loadRound/" + code).then((res: any) => {
      if (res.page_status === 1) {
        this.course = res.round
      } else {
        location.href = 'https://e-learning.aimc.or.th'
      }
    })
  };

  save() {
    let data = {
      user_ref: this.formGroup.value.user_ref,
      name_title: this.formGroup.value.name_title,
      first_name: this.formGroup.value.first_name,
      last_name: this.formGroup.value.last_name,
      number_phone: this.formGroup.value.number_phone,
      user_email: this.formGroup.value.user_email,
      cid: this.formGroup.value.cid,
      code: this.url
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success ml-2',
        cancelButton: 'btn btn-dark ml-2'
      },
      buttonsStyling: false
    });
    let html = "รหัสพนักงาน : " + data.user_ref + "<br/>";
    html += "รหัสบัตรประชาชน  : " + data.cid + "<br/>";
    html += "ชื่อ  : " + data.name_title + " " + data.first_name + " " + data.last_name + "<br/>";
    html += "เบอร์โทรศัพท์  : " + data.number_phone + "<br/>";
    html += "E-mail  : " + data.user_email + "<br/>";
    Swal.fire({
      // icon: 'question',
      title: 'ยืนยันการบันทึก?',
      html: html,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'บันทึก',
      cancelButtonText: 'แก้ไข'
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.postData_nologin('../Service/register_data', data).then((res: any) => {
          if (res - 0 == 1) {
            swalWithBootstrapButtons.fire({
              title: 'บันทึกข้อมูลเรียบร้อยแล้ว',
              text: "รอผลการอนุมัติภายใน 3 วันทำการระบบจะแจ้งผลการอนุมัติ และข้อมูลการเข้าระบบไปยัง email ของท่าน",
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'ตกลง',
              //                                                cancelButtonText: 'ปิดออก',
              reverseButtons: true
            }).then(function (result) {
              window.location.reload();
              // window.location.href = "https://e-learning.aimc.or.th/";
            });
            // Swal.fire('บันทึกสำเร็จ!', '', 'success').then(() => {
            //   location.href = 'https://e-learning.aimc.or.th'
            // })
          } else if (res - 0 == 2) {
            Swal.fire('คุณเคยลงทะเบียนแล้ว', '', 'warning');
          } else if (res - 0 == 3) {
            // Swal.fire('รอบนี้มีคนลงทะเบียเต็มแล้ว', '', 'warning');
             Swal.fire('ปิดรับลงทะเบียนแล้ว', '', 'warning');
          } else if (res - 0 == 4) {
            Swal.fire('คุณเคยลงทะเบียนหลักสูตรนี้ในรอบอื่นแล้ว', '', 'warning');
          } else {
            Swal.fire('ผิดผลาด!', '', 'error')
          }
        })
      }
    })
  }
}
