<app-header2></app-header2>

<div class="content" *ngIf="_statusPage === 1">
  <div class="header-title">
    <div class="p-4 text-center">
      <label class="font-weight-bold h2 text-white" data-toggle="collapse" href="#collapseExample"
        style="cursor: pointer;" (click)="moredetail()">
        INHOUSE : {{_inhouse.company_name}}
        &nbsp;
        <i class="{{_icon_more}}" style="font-size: x-large;"></i>
      </label>
      <div class="collapse" id="collapseExample">
        <div class="card card-body text-left">
          <div class="pt-3">
            <p>
              <label class="font-weight-bold">ชื่อผู้ประสานงาน </label>
              &nbsp;
              <span>{{_inhouse.coordinator_name}}</span>
            </p>
            <p>
              <label class="font-weight-bold">เบอร์โทร </label>
              &nbsp;
              <span>{{_inhouse.phone_number}}</span>
            </p>
            <p>
              <label class="font-weight-bold">Email </label>
              &nbsp;
              <span>{{_inhouse.email}}</span>
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row row-course">
      <div class="col-12">
        <label class="h3 pl-2 my-4 font-weight-bold" style="margin-left: -3vw;">
          รุ่นการอบรมที่กำลังเปิดรับสมัคร
        </label>
      </div>
    </div>
    <div class="row row-course-list">
      <div class="col-sm-12 col-md-6 col-lg-3 mb-3" *ngFor="let item of _courese">
        <div class="card card-course shadow-sm card-courese-opening" style="border-radius: 0px 0px 20px 20px;">
          <a routerLink="/register-round/{{item.share_code}}" class="overflow-hidden">
            <img class="card-img-top zoom " src="{{item.data.course.img}}" alt="Card image cap">
          </a>
          <div class="card-body">
            <div class="bg-danger badge badge-danger float-right" *ngIf="item.data.count==item.max_user">เต็มแล้ว</div>
            <div class="row title-time">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 fs-round">
                <label class="title-round">
                  จำนวนผู้อบรม ( {{item.data.count}} / {{item.max_user}} ) คน
                </label>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <a routerLink="/register-round/{{item.share_code}}">
                  <h5 class="fw-bolder">{{item.title}}</h5>
                </a>
              </div>
              <div class="col-12">
                <div>
                  <label style="font-size: 14px;" class="fw-bolder">ลงทะเบียนได้ถึง :
                    &nbsp;</label><span>{{api.get_text_date(item.limit_date)}}</span>
                </div>
                <div>
                  <label style="font-size: 14px;" class="fw-bolder">วันเริ่มการอบรม : &nbsp;</label>
                  <span>{{api.get_text_date(item.time_start)}}</span>
                </div>
                <div>
                  <label style="font-size: 14px;" class="fw-bolder">วันสิ้นสุดการอบรม : &nbsp;</label>
                  <span>{{api.get_text_date(item.time_end)}}</span>
                </div>
              </div>
              <div class="col-12 text-center mt-2">
                <button class="btn btn-register btn-block text-white w-50"
                  [routerLink]="'/register-round/'+item.share_code">สมัคร</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="_statusPage === 2">
  <div class="container">
    <div class="m-5 text-center">
      <img src="../../assets/images/404-error.jpg" alt="" width="100%">
    </div>
  </div>
</div>

<app-footer2></app-footer2>