<footer>
    <div class="container">
  
  
      <a href="javascript:void(0)" (click)="onActivate()" class="ml-auto float-right"><img src="../../../assets/images/up.png"> Back to
        top
      </a>
      <div>
        © 2021 AIMC E-Learning
      </div>
    </div>
  </footer>