import { ConnectApiService } from './../connect.api.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-courese',
  templateUrl: './detail-courese.component.html',
  styleUrls: ['./detail-courese.component.scss']
})
export class DetailCoureseComponent implements OnInit {

  _roundID: any = 0
  constructor(public api: ConnectApiService, private router: ActivatedRoute) {
    this._roundID = this.router.snapshot.paramMap.get("id");
  }

  page: number = 1
  _courese: any = [1, 2, 3, 4, 5, 6]

  ngOnInit(): void {
    this.goTop()
    this.loadRoundByID(this._roundID)
  }

  _courses: any = []
  _round: any = []
  _side : any = []
  loadRoundByID(id) {
    this.api.getData_nologin('../Front_web/loadRoundByID/' + id).then((res: any) => {
      if (res.flag == 1) {
        this._courses = res.course
        this._round = res.round
        this._side = res.side
        this.calHr()
      }
    })
  }

  hr: any = { hr: 0, min: 0 }
  calHr() {
    let tmp : any = 0
    this._courses.forEach(el => {
      tmp += el.duration
    });
    tmp = tmp.toFixed(2)
    this.hr = { hr: tmp.slice(0,2), min: tmp.slice(-2) }
    console.log(this.hr);

  }

  goTop() {
    window.scrollTo(0, 0);
  }

  pickMenu(type) {
    this.page = type
  }

}
