import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConnectApiService } from '../connect.api.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  Login: FormGroup;
  @ViewChild('password') passwordElement: ElementRef;
  constructor(private _formBuilder: FormBuilder, public api: ConnectApiService, private router: Router) { }
  show_error = false;
  show_success = false;
  ngOnInit(): void {
    this.Login = this._formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(13)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }
  login_func() {
    console.log(this.Login);
    if (this.Login.invalid) {
      return;
    }
    $("#loading").show();
    this.api.login(this.Login.value).then((res: any) => {
      console.log(res);
      $("#loading").hide();
      if (res?.flag == 'success') {
        this.show_error = false;
        this.show_success = true;
        localStorage.setItem('auth_token', res.token);
        this.api.storage_set("userdata", res.data);
        this.api.success("เข้าสู่ระบบเรียบร้อยแล้ว");
        setTimeout(() => {
          this.router.navigate(['/home']);
        }, 2000);
      } else {
        this.show_error = true;
        this.show_success = false;
        // this.api.error(res.error);
      }
    });

  }
  user_enter() {
    console.log("user_enter");
    this.passwordElement.nativeElement.focus();
  }
  pass_enter() {
    this.login_func();
  }

}
