import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WebcamInitError } from 'ngx-webcam';
import { ConnectApiService } from '../connect.api.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {

  constructor(private modalService: NgbModal, private api: ConnectApiService, private router: Router) { }
  data = {
    camera: true,
    video: true,
    sound: false,
    javascript: false,
    pdf: true,
    browser: true,
    cookie: false
  }
  ngOnInit(): void {
    // if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
    //   // Firefox 38+ seems having support of enumerateDevicesx
    //   this.data.camera = true;
    // }

    // navigator.getMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);
    // navigator.getMedia({ video: true }, () => {

    // }, () => {
    //   this.data.camera = false;
    //   // webcam is not available
    // });

    this.data.javascript = true;
    this.data.sound = true;
    this.data.pdf = true;
    setTimeout(() => {

      localStorage.setItem('test', 'test');
      let setup = localStorage.getItem("test");
      if (setup != null) {
        this.data.cookie = true;
        if (!this.have_error()) {
          localStorage.setItem('setup', 'success');
        }

      }
      console.log(setup);

    }, 1000);


  }
  handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      console.warn("Camera access was not allowed by user!");
      this.data.camera = false;

    }else{
        this.data.camera = true;
    }
  }
  view_video(content) {
    this.modalService.open(content, { size: 'md' });
  }
  reload() {
    window.location.reload();
  }
  have_error() {
    return !(this.data.browser && this.data.camera && this.data.cookie && this.data.javascript && this.data.pdf && this.data.sound && this.data.video);

  }
  logout() {
    localStorage.setItem('auth_token', null);
    this.api.storage_set("userdata", null);
    this.router.navigate(['/login']);
  }
}
